import { mapActions, mapState } from 'vuex'
// import * as XLSX from 'xlsx/xlsx.mjs'
// import store from '@/store'
// import axios from 'axios'
// import router from '@/router/'
// import PanelEntregaDiaria from '@/components/Layout/Panel_entrega_diaria/PanelEntregaDiariaView.vue'
// import BarChart from '@/components/Layout/Menu/Graficos/BarChart/BarChart.vue'
// import store from '@/store'

export default {
  name: 'CrearComentariosDetalleView',
  components: {
    // PanelEntregaDiaria
  },
  props: {
    dataComentario: null
  },
  data: function () {
    return {
      comentario: '',
      nroCometario: 0,
      nroDocumento: 0,
      fechaRuta: '',
      fechaHoraComentario: '',
      nroCliente: '',
      nroTransporte: '',
      nombreCliente: '',
      analista: '',
      tipoCoordinacion: '',
      origenContacto: '',
      tipoGestion: '',
      respuesta: '',
      motivoNoEntrega: '',
      // estadoComentario: '',
      estado: '',
      selectedCoordinacion: null,
      optionsCoordinacion: [
        { value: null, text: 'Seleccione Tipo Coordinacion' },
        { value: 'Comercial', text: 'Comercial' },
        { value: 'Logística', text: 'Logística' },
        { value: 'Otros', text: 'Otros' }
      ],
      selectedOrigenContacto: null,
      optionsOrigenContacto: [
        { value: null, text: 'Seleccione Origen Contacto' },
        { value: 'Mesa Ayuda', text: 'Mesa Ayuda' },
        { value: 'Área Andina', text: 'Área Andina' }
      ],
      selectedGestion: null,
      optionsGestion: [
        { value: null, text: 'Seleccione Tipo Gestion' },
        { value: 'Incidencia', text: 'Incidencia' },
        { value: 'No Incidencia', text: 'No Incidencia' }
      ],
      selectedRespuesta: null,
      optionsRespuesta: [
        { value: null, text: 'Seleccione Respuesta' },
        { value: 'Satisfactoria', text: 'Satisfactoria' },
        { value: 'Sin Respuesta', text: 'Sin Respuesta' },
        { value: 'Pendiente', text: 'Pendiente' }
      ],
      options: [
        { value: null, text: '--SELECCIONAR MOTIVO NO ENTREGA--' },
        { value: 'Motivos MDA', text: 'Motivos MDA' },
        { value: 'CERRADO (06)', text: 'CERRADO (06)' },
        { value: 'CLIENTE ANULA PEDIDO (08)', text: 'CLIENTE ANULA PEDIDO (08)' },
        { value: 'DIFERENCIA EN CONDICIÓN DE PAGO (11)', text: 'DIFERENCIA EN CONDICIÓN DE PAGO (11)' },
        { value: 'DIFICULTAD EN RUTA (ASALTO/INACCESIBLE/CAMIÓN EN PANNE) (13)', text: 'DIFICULTAD EN RUTA (ASALTO/INACCESIBLE/CAMIÓN EN PANNE) (13)' },
        { value: 'DUPLICADO/MAL DIGITADO (14)', text: 'DUPLICADO/MAL DIGITADO (14)' },
        { value: 'ENVASE (25)', text: 'ENVASE (25)' },
        { value: 'EXCESO DE CLIENTES (24)', text: 'EXCESO DE CLIENTES (24)' },
        { value: 'FALTA DE PRODUCTO EN (26)', text: 'FALTA DE PRODUCTO EN (26)' },
        { value: 'FUERA DE RUTA (23)', text: 'FUERA DE RUTA (23)' },
        { value: 'HORARIO INADECUADO (20)', text: 'HORARIO INADECUADO (20)' },
        { value: 'PROBLEMA DE FECHA (12)', text: 'PROBLEMA DE FECHA (12)' },
        { value: 'PRODUCTO DETERIORADO (04)', text: 'PRODUCTO DETERIORADO (04)' },
        { value: 'SIN DINERO (05)', text: 'SIN DINERO (05)' },
        { value: 'SIN ORDEN DE COMPRA (18)', text: 'SIN ORDEN DE COMPRA (18)' },
        { value: 'SOBRE STOCK (10)', text: 'SOBRE STOCK (10)' },
        { value: 'CLIENTE NO VISITADO', text: 'CLIENTE NO VISITADO' }
      ]
    }
  },
  watch: {
  },
  mounted () {
    console.log(this.dataComentario)
    this.nroCometario = this.dataComentario.nro_comentario
    this.analista = this.dataComentario.analista
    this.fechaRuta = this.dataComentario.fecha_ruta
    this.nroCliente = this.dataComentario.nro_cliente
    this.nroTransporte = this.dataComentario.nro_transporte
    this.nombreCliente = this.dataComentario.nombre_cliente
    this.nroDocumento = this.dataComentario.nro_documento
    const date = new Date()
    const fechaActual = `${
      date.getFullYear().toString().padStart(4, '0')}-${
        (date.getMonth() + 1).toString().padStart(2, '0')}-${
          date.getDate().toString().padStart(2, '0')} ${
            date.getHours().toString().padStart(2, '0')}:${
              date.getMinutes().toString().padStart(2, '0')}:${
                date.getSeconds().toString().padStart(2, '0')}`
    this.fechaHoraComentario = fechaActual
  },
  methods: {
    ...mapActions('Comentarios', [
      'insertComentarioDetalle'
    ]),
    insertarComentario () {
      console.log('insertarComentario')
      const comentarioData = {}
      comentarioData.id = this.nroCometario
      comentarioData.comentario = [
        {
          comentario: this.comentario,
          nro_documento: this.nroDocumento,
          fecha_ruta: this.fechaRuta,
          fecha_comentario: this.fechaHoraComentario,
          nro_cliente: this.nroCliente,
          nro_transporte: this.nroTransporte,
          nombre_cliente: this.nombreCliente,
          analista: this.analista,
          tipo_coordinacion: this.selectedCoordinacion,
          origen_contacto: this.selectedOrigenContacto,
          tipo_gestion: this.selectedGestion,
          respuesta: this.selectedRespuesta,
          motivo_no_entrega: this.motivoNoEntrega,
          estado: this.estado
        }
      ]
      console.log(comentarioData)
      this.insertComentarioDetalle(comentarioData).then((res) => {
        this.$emit('updateComentariosDetalle', this.comentarioDetalle)
        this.$emit('updateComentarios', this.comentarioDetalle)
      })
    }
  },
  computed: {
    ...mapState('Comentarios', ['comentarioDetalle']),
    estadoComentario () {
      if (this.comentario.length > 0) {
        return true
      } else {
        return false
      }
    }
  }
}

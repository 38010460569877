import { mapActions, mapState } from 'vuex'
// import * as XLSX from 'xlsx/xlsx.mjs'
// import store from '@/store'
import EditarComentariosDetalleView from '@/components/Layout/Comentarios_detalle/Editar_comentarios_detalle/EditarComentariosDetalleView.vue'
// import axios from 'axios'
// import router from '@/router/'
// import ComentariosDetalleView from '@/components/Layout/Comentarios_detalle/ComentariosDetalleView.vue'
// import BarChart from '@/components/Layout/Menu/Graficos/BarChart/BarChart.vue'
// import store from '@/store'

export default {
  name: 'EditarComentarioView',
  components: {
    EditarComentariosDetalleView
  },
  props: {
    data: null
  },
  data: function () {
    return {
      dataJson: [],
      selectedClasificacionComercial: null,
      optionsClasificacionComercial: [
        { value: null, text: 'Seleccione Clasificacion Comercial' },
        { value: 'Coordinaciones de horarios', text: 'Coordinaciones de horarios' },
        { value: 'Estado de pedido', text: 'Estado de pedido' },
        { value: 'Faltas de productos', text: 'Faltas de productos' },
        { value: 'Cancelación de visita', text: 'Cancelación de visita' }
      ],
      selectedCoordinacion: null,
      optionsCoordinacion: [
        { value: null, text: 'Seleccione Tipo Coordinacion' },
        { value: 'Comercial', text: 'Comercial' },
        { value: 'Logística', text: 'Logística' },
        { value: 'Otros', text: 'Otros' }
      ],
      selectedOrigenContacto: null,
      optionsOrigenContacto: [
        { value: null, text: 'Seleccione Origen Contacto' },
        { value: 'Mesa Ayuda', text: 'Mesa Ayuda' },
        { value: 'Área Andina', text: 'Área Andina' }
      ],
      selectedGestion: null,
      optionsGestion: [
        { value: null, text: 'Seleccione Tipo Gestion' },
        { value: 'Incidencia', text: 'Incidencia' },
        { value: 'No Incidencia', text: 'No Incidencia' },
        { value: 'Gestion tiempo supermercado', text: 'Gestion tiempo supermercado' }
      ],
      selectedRespuesta: null,
      optionsRespuesta: [
        { value: null, text: 'Seleccione Respuesta' },
        { value: 'Satisfactoria', text: 'Satisfactoria' },
        { value: 'Sin Respuesta', text: 'Sin Respuesta' },
        { value: 'Pendiente', text: 'Pendiente' }
      ],
      nroComentario: 0,
      fechaRuta: '',
      nroCliente: '',
      nroTransporte: '',
      nombreCliente: '',
      nroDocumento: '',
      fechaHoraComentario: '',
      analista: '',
      comentariosDetalle: [],
      idTransporte: '',
      motivoNoEntrega: '',
      selectEstado: null,
      optionsEstado: [
        { value: null, text: 'Seleccionar estado' },
        { value: 'Entregado', text: 'Entregado' },
        { value: 'No Entregado', text: 'No Entregado' },
        { value: 'Entrega Parcial', text: 'Entrega Parcial' },
        { value: 'En Gestion', text: 'En Gestion' }
      ],
      options: [],
      editarComentarioDetalleModal: {
        id: 'editarComentarioDetalle-modal',
        title: '',
        content: ''
      },
      updateComentariosDetalle: ''
    }
  },
  watch: {
    updateComentariosDetalle: function (val) {
      // console.log('updateComentariosDetalle')
      this.hideEditarComentarioDetalleModal()
      this.$emit('updateComentarios', new Date())
    }
  },
  mounted () {
    this.options = this.motivosNoEntrega
    // console.log(typeof this.data)
    if (typeof this.data !== 'object') {
      this.dataJson = JSON.parse(this.data)
    } else {
      this.dataJson = this.data
    }
    // console.log(this.dataJson)
    this.comentariosDetalle = this.dataJson.comentarios
    this.selectedCoordinacion = this.dataJson.tipo_coordinacion
    this.selectedOrigenContacto = this.dataJson.origen_contacto
    this.selectedGestion = this.dataJson.tipo_gestion
    this.selectedRespuesta = this.dataJson.respuesta
    this.motivoNoEntrega = this.dataJson.motivo_no_entrega
    this.fechaRuta = this.dataJson.fecha_ruta.replace('T', ' ').substring(0, 19)
    this.estado = this.dataJson.estado
    this.nroCliente = this.dataJson.nro_cliente
    this.nroTransporte = this.dataJson.nro_transporte
    this.nombreCliente = this.dataJson.nombre_cliente
    this.idTransporte = this.dataJson.id_entrega_diaria
    this.motivoNoEntrega = this.dataJson.motivo_no_entrega_final
    this.nroComentario = this.dataJson.nro_comentario
    this.selectEstado = this.dataJson.estado
    if (this.dataJson.nro_documento === undefined) {
      console.log('nro documento null')
    } else {
      this.nroDocumento = this.dataJson.nro_documento
    }
    this.analista = this.dataJson.analista
    // this.comentario = this.dataJson.comentarios
    const date = new Date()
    const fechaActual = `${
      date.getFullYear().toString().padStart(4, '0')}-${
        (date.getMonth() + 1).toString().padStart(2, '0')}-${
          date.getDate().toString().padStart(2, '0')} ${
            date.getHours().toString().padStart(2, '0')}:${
              date.getMinutes().toString().padStart(2, '0')}:${
                date.getSeconds().toString().padStart(2, '0')}`
    this.fechaHoraComentario = fechaActual
  },
  methods: {
    ...mapActions('Comentarios', [
      'getcomentariosByDocumento', 'getcomentariosByTransporte', 'getcomentariosAll', 'insertComentarioGeneral', 'updateComentario'
    ]),
    ...mapActions('EntregaDiaria', [
      'getEntregaDiaria', 'actualizaEntregaDiariaMotivoNoEntrega'
    ]),
    editarComentarioDetalle (item, index, button) {
      this.editarComentarioDetalleModal.title = 'Editar Comentario Hijo'
      this.editarComentarioDetalleModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.editarComentarioDetalleModal.id, button)
    },
    hideEditarComentarioDetalleModal () {
      this.$refs[this.editarComentarioDetalleModal.id].hide()
    },
    makeToast (mensaje, titulo, variant) {
      this.$bvToast.toast(mensaje, {
        title: titulo,
        variant: variant,
        solid: true
      })
    },
    editarComentario () {
      console.log('editar comentario')
      let nroDocumento = ''
      if (this.dataJson.nro_documento === undefined) {
        console.log('nro documento null')
        nroDocumento = 0
      } else {
        nroDocumento = this.nroDocumento
      }
      let coordinacion = this.selectedCoordinacion
      if (this.selectedClasificacionComercial !== null && this.selectedCoordinacion === 'Comercial') {
        coordinacion = this.selectedCoordinacion + ' - ' + this.selectedClasificacionComercial
      }
      const comentarioActualizar = {
        nro_documento: nroDocumento,
        fecha_ruta: this.fechaRuta,
        fecha_comentario: this.fechaHoraComentario,
        nro_cliente: this.nroCliente,
        nro_transporte: this.nroTransporte,
        nombre_cliente: this.nombreCliente,
        analista: this.analista,
        tipo_coordinacion: coordinacion,
        origen_contacto: this.selectedOrigenContacto,
        tipo_gestion: this.selectedGestion,
        respuesta: this.selectedRespuesta,
        motivo_no_entrega: this.motivoNoEntrega,
        id_entrega_diaria: this.idTransporte,
        nro_comentario: this.nroComentario,
        estado: this.selectEstado
      }
      // console.log(comentarioActualizar)
      this.updateComentario(comentarioActualizar).then((res) => {
        // console.log(res)
        if (res) {
          this.makeToast('Comentario Transporte: ' + comentarioActualizar.nro_transporte, 'Comentario Actualizado Exitosamente!', 'success')
        } else {
          this.makeToast('Comentario Transporte: ' + comentarioActualizar.nro_transporte, 'Error!', 'warning')
        }
        this.$emit('updateComentarios', new Date())
      })
    },
    actualizaMotivoNoEntregaInicial () {
      const entrega = this.dataJson
      console.log(this.motivoNoEntrega)
      entrega.motivo_no_entrega_final = this.motivoNoEntrega
      console.log(entrega)
      this.actualizaEntregaDiariaMotivoNoEntrega(entrega).then((res) => {
        this.$emit('updateComentarios', this.comentarios)
      })
    }
  },
  computed: {
    ...mapState('Comentarios', ['comentarios']),
    ...mapState(['motivosNoEntrega'])
    // estadoComentario () {
    //   if (this.comentario.length > 0) {
    //     return true
    //   } else {
    //     return false
    //   }
    // }
  }
}

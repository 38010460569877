import { mapActions, mapState } from 'vuex'
import * as XLSX from 'xlsx'
// import * as XLSX from 'xlsx/xlsx.mjs'
// import store from '@/store'
// import axios from 'axios'
// import router from '@/router/'
// import PanelEntregaDiaria from '@/components/Layout/Panel_entrega_diaria/PanelEntregaDiariaView.vue'
// import BarChart from '@/components/Layout/Menu/Graficos/BarChart/BarChart.vue'
// import store from '@/store'
// import Comentarios from '@/components/Layout/Comentarios/ComentariosView.vue'
// import CrearComentariosView from '@/components/Layout/Comentarios/Crear_comentarios/CrearComentariosView.vue'

export default {
  name: 'CargarClientesView',
  components: {
  },
  props: {
  },
  data: function () {
    return {
      file: null,
      arrayBuffer: null,
      filelist: null,
      clientesExcel: []
    }
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    ...mapActions('Clientes', [
      'insertClientes'
    ]),
    addfile (event) {
      console.log(event)
      this.file = event.target.files[0]
      const fileReader = new FileReader()
      try {
        fileReader.readAsArrayBuffer(this.file)
      } catch (exception) {
        console.log('error')
        this.filelist = []
        return ''
      }
      fileReader.onload = (e) => {
        this.arrayBuffer = fileReader.result
        const data = new Uint8Array(this.arrayBuffer)
        const arr = []
        for (var i = 0; i !== data.length; ++i) {
          arr[i] = String.fromCharCode(data[i])
        }
        const bstr = arr.join('')
        const workbook = XLSX.read(bstr, { type: 'binary' })
        // para leer diferentes hojas editar la posicion del array workbook.SheetNames[0]
        const firstSheetName = workbook.SheetNames[0]
        const worksheet = workbook.Sheets[firstSheetName]
        // console.log(XLSX.utils.sheet_to_json(worksheet, { raw: true }))
        // console.log(XLSX.utils.sheet_to_json(worksheet, { raw: true }))
        this.clientesExcel = XLSX.utils.sheet_to_json(worksheet, { raw: true })
        console.log(this.clientesExcel)
        this.filelist = []
        console.log(this.filelist)
      }
    },
    cargarExcel () {
      this.insertClientes(this.clientesExcel).then((res) => {
        console.log('cargarExcel')
        const toast = {}
        toast.body = 'Se ha cargado el archivo con exito'
        toast.title = 'Carga Exitosa'
        toast.variant = 'success'
        this.makeToast(toast)
        this.clientesExcel = []
        this.$emit('updateClientes', this.clientes)
      })
    },
    makeToast (toast) {
      this.$bvToast.toast(toast.body, {
        title: toast.title,
        variant: toast.variant,
        autoHideDelay: 2000
      })
    }
  },
  computed: {
    ...mapState('Clientes', ['clientes'])
  }
}

// import { mapActions } from 'vuex'

export default {
  name: 'ContadorGestionView',
  components: {
  },
  props: {
    periodo: {
      type: Object,
      default: () => ({ check_comentarios: 0 })
    }
  },
  data: function () {
    return {
      segundos: 0,
      intervaloID: 0,
      intervaloContadorID: 0
    }
  },
  watch: {
    periodo: {
      handler: function (nuevoValor, valorAnterior) {
        this.segundos = 0
        // this.detenerRepeticion()
        this.detenerRepeticionContador()
        this.iniciarRepeticion()
      },
      deep: true
    }
  },
  mounted () {
    // 2023-06-17T21:00:00.000+00:00
    // 2023-06-17T21:00:00
    this.iniciarRepeticion()
  },
  methods: {
    init () {
      const periodo = this.obtieneFecha()
      if (periodo !== null && periodo !== undefined) {
        this.actualizarSegundos(periodo)
        this.detenerRepeticion()
      } else if (periodo === undefined) {
        this.detenerRepeticion()
      }
    },
    detenerRepeticionContador () {
      clearInterval(this.intervaloContadorID)
    },
    detenerRepeticion () {
      clearInterval(this.intervaloID)
    },
    iniciarRepeticion () {
      this.intervaloID = setInterval(this.init, 1000) // Repetir cada 1 segundo (1000 milisegundos)
    },
    obtieneFecha () {
      if (this.periodo.estado === 'En Gestion') {
        if (this.periodo.check_comentarios.length !== undefined && this.periodo.check_comentarios.length > 0) {
          console.log(this.periodo.check_comentarios[0].fecha_comentario.substring(0, 19))
          if (this.periodo.check_comentarios[0].fecha_comentario.substring(0, 19) !== undefined) {
            return this.periodo.check_comentarios[0].fecha_comentario.substring(0, 19)
          }
        } else {
          return null
        }
      } else {
        return undefined
      }
    },
    formatoNumero (numero) {
      return numero.toString().padStart(2, '0')
    },
    actualizarSegundos (date) {
      const startDate = new Date(date) // Fecha de inicio
      this.intervaloContadorID = setInterval(() => {
        const currentDate = new Date()
        const diffInSeconds = Math.floor((currentDate - startDate) / 1000)
        this.segundos = diffInSeconds
      }, 1000)
    }
  },
  computed: {
    tiempoFormateado () {
      const horas = Math.floor(this.segundos / 3600)
      const minutos = Math.floor((this.segundos % 3600) / 60)
      const segundosRestantes = this.segundos % 60
      return `${this.formatoNumero(horas)}:${this.formatoNumero(minutos)}:${this.formatoNumero(segundosRestantes)}`
    }
  }
}

import { mapState } from 'vuex'
// import * as XLSX from 'xlsx/xlsx.mjs'
// import store from '@/store'
// import axios from 'axios'
// import router from '@/router/'
import CrearComentariosDetalle from '@/components/Layout/Comentarios_detalle/Crear_comentarios_detalle/CrearComentariosDetalle.vue'
import $ from 'jquery'
// import BarChart from '@/components/Layout/Menu/Graficos/BarChart/BarChart.vue'
// import store from '@/store'

export default {
  name: 'ComentariosDetalleView',
  components: {
    CrearComentariosDetalle
  },
  props: {
    comentarios: null
  },
  data: function () {
    return {
      comentariosJson: [],
      items: [],
      fields: [
        { key: 'id', label: 'id', sortable: true, class: 'text-center', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'comentario', label: 'comentario', sortable: true, sortDirection: 'desc', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'nro_documento', label: 'nro_documento', sortable: true, class: 'text-center', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'fecha_ruta', label: 'fecha_ruta', sortable: true, class: 'text-center', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'fecha_comentario', label: 'fecha_comentario', sortable: true, class: 'text-center', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'nro_cliente', label: 'nro_cliente', sortable: true, class: 'text-center', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'nro_transporte', label: 'nro_transporte', sortable: true, class: 'text-center', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'nombre_cliente', label: 'nombre_cliente', sortable: true, class: 'text-center', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'analista', label: 'analista', sortable: true, class: 'text-center', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'tipo_coordinacion', label: 'tipo_coordinacion', sortable: true, class: 'text-center', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'origen_contacto', label: 'origen_contacto', sortable: true, class: 'text-center', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'tipo_gestion', label: 'tipo_gestion', sortable: true, class: 'text-center', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'respuesta', label: 'respuesta', sortable: true, class: 'text-center', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'motivo_no_entrega', label: 'motivo_no_entrega', sortable: true, class: 'text-center', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'estado', label: 'estado', sortable: true, class: 'text-center', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'acciones', label: 'Acciones', sortable: true, class: 'text-center', thStyle: { background: '#FF9B00', color: '#ffffff' } }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, { value: 100, text: 'Show a lot' }],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: ''
      },
      deleteModal: {
        id: 'delete-modal',
        title: '',
        content: ''
      },
      activeColor: 'arial',
      fontSize: 13,
      backgroundColor: '#91c27d',
      updateComentariosDetalle: '',
      options: []
    }
  },
  watch: {
    updateComentariosDetalle: function (val) {
      console.log('observador updateComentariosDetalle')
      console.log(this.updateComentariosDetalle)
      this.resetInfoModal()
      this.hideModal()
      // this.$refs[this.infoModal.id].hide()
      this.items = this.updateComentariosDetalle.comentarios
      for (const item in this.items) {
        this.items[item].fecha_ruta = this.items[item].fecha_ruta.replace('T', ' ').substring(0, 18)
        this.items[item].fecha_comentario = this.items[item].fecha_comentario.replace('T', ' ').substring(0, 18)
      }
      this.totalRows = this.items.length
      const toast = {}
      toast.body = 'Cliente Creado Con Exito'
      toast.title = 'Tabla Clientes Actualizada'
      toast.variant = 'success'
      this.makeToast(toast)
    }
  },
  mounted () {
    this.options = this.motivosNoEntrega
    $('.close').click(function (e) {
      console.log('close')
    })
    this.comentariosJson = JSON.parse(this.comentarios)
    this.items = this.comentariosJson.comentarios
    for (const item in this.items) {
      this.items[item].fecha_ruta = this.items[item].fecha_ruta.replace('T', ' ').substring(0, 18)
      this.items[item].fecha_comentario = this.items[item].fecha_comentario.replace('T', ' ').substring(0, 18)
    }
    this.totalRows = this.items.length
  },
  methods: {
    // ...mapActions('AdministradorFlota', [
    //   'getTransportes', 'getConductores', 'getAyudantes', 'getCamiones'
    // ])
    info (item, index, button) {
      this.infoModal.title = 'Documentos Transporte ' + item.nro_transporte
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal () {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    hideModal () {
      this.$refs[this.infoModal.id].hide()
    },
    infoDeleteModal (item, index, button) {
      this.deleteModal.title = 'Eliminar Comentario ' + item.nombre_fantasia
      this.deleteModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.deleteModal.id, button)
    },
    resetDeleteModal () {
      this.deleteModal.title = ''
      this.deleteModal.content = ''
    },
    hideDeleteModal () {
      this.$refs[this.deleteModal.id].hide()
    },
    onFiltered (filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    crearComentarioDetalle () {
      console.log('crear comentario detalle')
      this.infoModal.title = 'Crear Sub Comentario'
      this.infoModal.content = ''
      this.$root.$emit('bv::show::modal', this.infoModal.id)
    },
    makeToast (toast) {
      this.$bvToast.toast(toast.body, {
        title: toast.title,
        variant: toast.variant,
        autoHideDelay: 5000
      })
    }
  },
  computed: {
    // ...mapState('AdministradorFlota', ['transportes'])
    ...mapState(['motivosNoEntrega']),
    sortOptions () {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key }
        })
    }
  }
}

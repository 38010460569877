import { render, staticRenderFns } from "@/components/Layout/Documentos_pedido/Documentos_pedido.html?vue&type=template&id=37bfc389&scoped=true&"
import script from "@/components/Layout/Documentos_pedido/Documentos_pedido.js?vue&type=script&lang=js&"
export * from "@/components/Layout/Documentos_pedido/Documentos_pedido.js?vue&type=script&lang=js&"
import style0 from "@/components/Layout/Documentos_pedido/Documentos_pedido.css?vue&type=style&index=0&id=37bfc389&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37bfc389",
  null
  
)

export default component.exports
import { mapActions, mapState } from 'vuex'
// import * as XLSX from 'xlsx/xlsx.mjs'
// import store from '@/store'
// import axios from 'axios'
// import router from '@/router/'
// import PanelEntregaDiaria from '@/components/Layout/Panel_entrega_diaria/PanelEntregaDiariaView.vue'
// import BarChart from '@/components/Layout/Menu/Graficos/BarChart/BarChart.vue'
// import store from '@/store'

export default {
  name: 'EditarComentariosDetalleView',
  components: {
    // PanelEntregaDiaria
  },
  props: {
    dataComentario: null
  },
  data: function () {
    return {
      dataComentarioJSON: {},
      comentario: '',
      nroCometario: 0,
      nroDocumento: 0,
      fechaRuta: '',
      fechaHoraComentario: '',
      nroCliente: '',
      nroTransporte: '',
      nombreCliente: '',
      analista: '',
      tipoCoordinacion: '',
      origenContacto: '',
      tipoGestion: '',
      respuesta: '',
      motivoNoEntrega: '',
      id: 0,
      // estadoComentario: '',
      estado: '',
      selectedCoordinacion: null,
      optionsCoordinacion: [
        { value: null, text: 'Seleccione Tipo Coordinacion' },
        { value: 'Comercial', text: 'Comercial' },
        { value: 'Logística', text: 'Logística' },
        { value: 'Otros', text: 'Otros' }
      ],
      selectedOrigenContacto: null,
      optionsOrigenContacto: [
        { value: null, text: 'Seleccione Origen Contacto' },
        { value: 'Mesa Ayuda', text: 'Mesa Ayuda' },
        { value: 'Área Andina', text: 'Área Andina' }
      ],
      selectedGestion: null,
      optionsGestion: [
        { value: null, text: 'Seleccione Tipo Gestion' },
        { value: 'Incidencia', text: 'Incidencia' },
        { value: 'No Incidencia', text: 'No Incidencia' }
      ],
      selectedRespuesta: null,
      optionsRespuesta: [
        { value: null, text: 'Seleccione Respuesta' },
        { value: 'Satisfactoria', text: 'Satisfactoria' },
        { value: 'Sin Respuesta', text: 'Sin Respuesta' },
        { value: 'Pendiente', text: 'Pendiente' }
      ],
      options: [
        { value: null, text: 'Seleccionar estado' },
        { value: 'Entregado', text: 'Entregado' },
        { value: 'No Entregado', text: 'No Entregado' },
        { value: 'Entrega Parcial', text: 'Entrega Parcial' },
        { value: 'En Gestion', text: 'En Gestion' }
      ]
    }
  },
  watch: {
  },
  mounted () {
    // console.log(this.dataComentario)
    this.dataComentarioJSON = JSON.parse(this.dataComentario)
    this.id = this.dataComentarioJSON.id
    this.nroCometario = this.dataComentarioJSON.nro_comentario
    this.analista = this.dataComentarioJSON.analista
    this.fechaRuta = this.dataComentarioJSON.fecha_ruta
    this.nroCliente = this.dataComentarioJSON.nro_cliente
    this.nroTransporte = this.dataComentarioJSON.nro_transporte
    this.nombreCliente = this.dataComentarioJSON.nombre_cliente
    this.nroDocumento = this.dataComentarioJSON.nro_documento
    this.comentario = this.dataComentarioJSON.comentario
    this.estado = this.dataComentarioJSON.estado
    this.selectedCoordinacion = this.dataComentarioJSON.tipo_coordinacion
    this.selectedOrigenContacto = this.dataComentarioJSON.origen_contacto
    this.selectedGestion = this.dataComentarioJSON.tipo_gestion
    this.selectedRespuesta = this.dataComentarioJSON.respuesta
    this.fechaHoraComentario = this.dataComentarioJSON.fecha_comentario
  },
  methods: {
    ...mapActions('Comentarios', [
      'insertComentarioDetalle', 'updateComentarioDetalle'
    ]),
    editarComentario () {
      console.log('editarComentario')
      const comentarioData = {
        comentario: this.comentario,
        nro_documento: this.nroDocumento,
        fecha_ruta: this.fechaRuta,
        fecha_comentario: this.fechaHoraComentario,
        nro_cliente: this.nroCliente,
        nro_transporte: this.nroTransporte,
        nombre_cliente: this.nombreCliente,
        analista: this.analista,
        tipo_coordinacion: this.selectedCoordinacion,
        origen_contacto: this.selectedOrigenContacto,
        tipo_gestion: this.selectedGestion,
        respuesta: this.selectedRespuesta,
        motivo_no_entrega: this.motivoNoEntrega,
        estado: this.estado,
        id: this.id
      }
      console.log(comentarioData)
      this.updateComentarioDetalle(comentarioData).then((res) => {
        // console.log(res)
        if (res) {
          this.makeToast('Comentario Transporte: ' + comentarioData.nro_transporte, 'Comentario Actualizado Exitosamente!', 'success')
        } else {
          this.makeToast('Comentario Transporte: ' + comentarioData.nro_transporte, 'Error!', 'warning')
        }
        this.$emit('updateComentariosDetalle', new Date())
      })
    },
    makeToast (mensaje, titulo, variant) {
      this.$bvToast.toast(mensaje, {
        title: titulo,
        variant: variant,
        solid: true
      })
    }
  },
  computed: {
    ...mapState('Comentarios', ['comentarioDetalle']),
    estadoComentario () {
      if (this.comentario.length > 0) {
        return true
      } else {
        return false
      }
    }
  }
}

import store from '@/store'
import { mapActions, mapState } from 'vuex'
// import * as XLSX from 'xlsx/xlsx.mjs'
// import store from '@/store'
// import axios from 'axios'
// import router from '@/router/'
// import PanelEntregaDiaria from '@/components/Layout/Panel_entrega_diaria/PanelEntregaDiariaView.vue'
// import BarChart from '@/components/Layout/Menu/Graficos/BarChart/BarChart.vue'
// import store from '@/store'

export default {
  name: 'CrearClientesView',
  components: {
    // PanelEntregaDiaria
  },
  props: {
  },
  data: function () {
    return {
      nroClienteSap: 0,
      razonSocial: '',
      nombreFantasia: '',
      telefono: '',
      contacto: '',
      direccionDespacho: '',
      territorioVenta: '',
      clienteFoco: '',
      canalVenta: '',
      analista: store.state.user
    }
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    ...mapActions('Clientes', [
      'getAllClientes', 'insertClientes'
    ]),
    crearCliente () {
      console.log('crearCliente')
      const clientes = []
      const telefonos = []
      telefonos.push(this.telefono)
      const cliente = {
        nro_cliente_sap: this.nroClienteSap,
        razon_social: this.razonSocial,
        nombre_fantasia: this.nombreFantasia,
        telefono: telefonos,
        contacto: this.contacto,
        direccion_despacho: this.direccionDespacho,
        territorio_venta: this.territorioVenta,
        cliente_foco: this.clienteFoco,
        canal_venta: this.canalVenta,
        analista_ma: this.analista
      }
      clientes.push(cliente)
      this.insertClientes(clientes).then((res) => {
        this.$emit('updateClientes', this.clientes)
      })
    }
  },
  computed: {
    ...mapState('Clientes', ['clientes'])
  }
}

// import { mapActions, mapState } from 'vuex'
// import * as XLSX from 'xlsx/xlsx.mjs'
import store from '@/store'
// import axios from 'axios'
// import router from '@/router/'
// import PanelEntregaDiaria from '@/components/Layout/Panel_entrega_diaria/PanelEntregaDiariaView.vue'
// import SideBarView from '@/components/Layout/Menu/SideBar/SideBarView.vue'
// import BarChart from '@/components/Layout/Menu/Graficos/BarChart/BarChart.vue'
// import store from '@/store'
import PanelEntregaDiaria from '@/components/Layout/Panel_entrega_diaria/PanelEntregaDiariaView.vue'
import ComentariosTotalesView from '@/components/Layout/Comentarios_totales/ComentariosTotalesView.vue'
import AdministracionClientesView from '@/components/Layout/Clientes/Administracion_clientes/AdministracionClientesView.vue'
import CargarArchivosView from '@/components/Layout/Administracion/Cargar_archivos/CargarArchivosView.vue'
import AdministracionUsuariosView from '@/components/Layout/Administracion/Administracion_usuarios/AdministracionUsuariosView.vue'
import ReportesGestionesView from '@/components/Layout/Reportes/Reportes_gestiones/ReportesGestionesView.vue'
import $ from 'jquery'

export default {
  name: 'SideBarView',
  components: {
    PanelEntregaDiaria,
    ComentariosTotalesView,
    AdministracionClientesView,
    CargarArchivosView,
    AdministracionUsuariosView,
    ReportesGestionesView
  },
  props: {
  },
  data: function () {
    return {
      user: '',
      imagenUsuario: '',
      entregaDiaria: true,
      comentariosTotales: false,
      clientesAdmnistracion: false,
      verComentarios: null,
      administracionUsuarios: false,
      administracionCargaArchivos: false,
      reportesGestiones: false,
      tipo: '',
      width: '140px',
      marginLeftItem: '-40%',
      marginLeftItemCliente: '-57%',
      marginLeftItemComentarios: '-44%',
      marginLeftItemReportes: '-55%',
      widthItem: '250px',
      marginLeft: '250px',
      slideEnable: true,
      mainContainerStyke: '120px',
      volverPanelEntrega: ''
    }
  },
  watch: {
    verComentarios: function (val) {
      console.log('entra en observador verComentarios')
      // console.log(this.verComentarios)
      if (this.verComentarios != null) {
        this.comentariosTotalesEnable()
      }
    },
    volverPanelEntrega: function (val) {
      // console.log('entra en observador volverPanelEntrega')
      this.entregaDiariaEnable()
    }
  },
  mounted () {
    // $(document).ready(function () {
    //   $('.sidebar-btn').click(function () {
    //     $('.wrapper').toggleClass('collapse')
    //   })
    // })
    this.user = store.state.user
    this.imagenUsuario = store.state.imagenUsuario
    $('#menu-toggle').click(function (e) {
      e.preventDefault()
      $('#wrapper').toggleClass('toggled')
    })
    $('#menu-toggle-2').click(function (e) {
      e.preventDefault()
      $('#wrapper').toggleClass('toggled-2')
      $('#menu ul').hide()
    })
    function initMenu () {
      $('#menu ul').hide()
      $('#menu ul').children('.current').parent().show()
      $('#menu ul:first').show()
      $('#menu li a').click(
        function () {
          var checkElement = $(this).next()
          if ((checkElement.is('ul')) && (checkElement.is(':visible'))) {
            return false
          }
          if ((checkElement.is('ul')) && (!checkElement.is(':visible'))) {
            $('#menu ul:visible').slideUp('normal')
            checkElement.slideDown('normal')
            return false
          }
        })
    }
    $(document).ready(function () { initMenu() })
  },
  methods: {
    // ...mapActions('AdministradorFlota', [
    //   'getTransportes', 'getConductores', 'getAyudantes', 'getCamiones'
    // ])
    test () {
      console.log('test')
      // $(document).ready(function () {
      //   $('.sidebar-btn').click(function () {
      //     $('.wrapper').toggleClass('collapse')
      //   })
      // })
    },
    slide () {
      console.log('slide')
      this.slideEnable = !this.slideEnable
      if (this.slideEnable) {
        this.width = '140px'
        this.widthItem = '250px'
        this.marginLeft = '250px'
        this.marginLeftItem = '-40%'
        this.marginLeftItemCliente = '-57%'
        this.marginLeftItemComentarios = '-44%'
        this.marginLeftItemReportes = '-55%'
        this.mainContainerStyke = '120px'
      } else {
        this.widthItem = '100px'
        this.width = '60px'
        this.marginLeft = '100px'
        this.marginLeftItem = '-35%'
        this.marginLeftItemCliente = '-35%'
        this.marginLeftItemComentarios = '-35%'
        this.marginLeftItemReportes = '-35%'
        this.mainContainerStyke = '30px'
      }
    },
    entregaDiariaEnable () {
      this.entregaDiaria = true
      this.comentariosTotales = false
      this.administracionUsuarios = false
      this.clientesAdmnistracion = false
      this.administracionCargaArchivos = false
      this.reportesGestiones = false
    },
    comentariosTotalesEnable () {
      this.entregaDiaria = false
      // this.verComentarios = null
      this.comentariosTotales = true
      this.administracionUsuarios = false
      this.clientesAdmnistracion = false
      this.administracionCargaArchivos = false
      this.reportesGestiones = false
    },
    comentariosTotalesSinFiltro () {
      this.verComentarios = null
      this.comentariosTotalesEnable()
    },
    clientesAdmnistracionEnable () {
      this.entregaDiaria = false
      this.comentariosTotales = false
      this.administracionUsuarios = false
      this.administracionCargaArchivos = false
      this.clientesAdmnistracion = true
      this.reportesGestiones = false
    },
    administracionUsuariosEnable () {
      this.entregaDiaria = false
      this.comentariosTotales = false
      this.clientesAdmnistracion = false
      this.administracionCargaArchivos = false
      this.administracionUsuarios = true
      this.reportesGestiones = false
    },
    administracionCargaArchivosEnable () {
      this.entregaDiaria = false
      this.comentariosTotales = false
      this.clientesAdmnistracion = false
      this.administracionUsuarios = false
      this.administracionCargaArchivos = true
      this.reportesGestiones = false
    },
    reportesGestionesEnable () {
      this.entregaDiaria = false
      this.comentariosTotales = false
      this.clientesAdmnistracion = false
      this.administracionUsuarios = false
      this.administracionCargaArchivos = false
      this.reportesGestiones = true
    }
  },
  computed: {
    // ...mapState('AdministradorFlota', ['transportes'])
  }
}

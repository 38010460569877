import { mapActions, mapState } from 'vuex'
// import * as XLSX from 'xlsx/xlsx.mjs'
import store from '@/store'
// import axios from 'axios'
// import router from '@/router/'
import CargarArchivosView from '@/components/Layout/Administracion/Cargar_archivos/CargarArchivosView.vue'
import AdministracionUsuariosView from '@/components/Layout/Administracion/Administracion_usuarios/AdministracionUsuariosView.vue'
import CrearUsuariosView from '@/components/Layout/Administracion/Administracion_usuarios/Crear_usuarios/CrearUsuariosView.vue'
import EditarUsuariosView from '@/components/Layout/Administracion/Administracion_usuarios/Editar_usuarios/EditarUsuariosView.vue'
// import BarChart from '@/components/Layout/Menu/Graficos/BarChart/BarChart.vue'
// import store from '@/store'

export default {
  name: 'AdministracionUsuariosView',
  components: {
    CargarArchivosView,
    AdministracionUsuariosView,
    CrearUsuariosView,
    EditarUsuariosView
  },
  props: {
  },
  data: function () {
    return {
      perfil: {},
      items: [],
      fields: [
        { key: 'username', label: 'Usuario', sortable: true, class: 'text-center', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'nombre', label: 'Nombre', sortable: true, sortDirection: 'desc', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'email', label: 'Correo', sortable: true, class: 'text-center', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'phone', label: 'Telefono', sortable: true, class: 'text-center', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        // { key: 'pswd', label: 'Clave', sortable: true, class: 'text-center', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'acciones', label: 'Accion', sortable: true, class: 'text-center', thStyle: { background: '#FF9B00', color: '#ffffff' } }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, { value: 100, text: 'Show a lot' }],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: ''
      },
      deleteModal: {
        id: 'delete-modal',
        title: '',
        content: ''
      },
      editModal: {
        id: 'edit-modal',
        title: '',
        content: ''
      },
      activeColor: 'arial',
      fontSize: 13,
      backgroundColor: '#91c27d',
      updateUsuarios: '',
      show: false
    }
  },
  watch: {
    updateUsuarios: function (val) {
      // if (this.updateClientes !== this.items) {
      // }
      console.log('observador updateUsuarios')
      this.usuariosPorGrupo()
      // this.items = this.usuarios
      // this.totalRows = this.items.length
      this.hideEditModalModal()
      this.hideModal()
      const toast = {}
      toast.body = 'Usuario Creado Con Exito'
      toast.title = 'Tabla Usuarios Actualizada'
      toast.variant = 'success'
      this.makeToast(toast)
    }
  },
  mounted () {
    this.usuariosPorGrupo()
    // this.perfil = store.state.perfilUsuario
    // let groupBitacora = 0
    // for (const group in this.perfil.grupos) {
    //   if (this.perfil.grupos[group].nombre === 'bitacora') {
    //     groupBitacora = this.perfil.grupos[group].group_id
    //   }
    // }
    // this.getUsuarios(groupBitacora).then((res) => {
    //   console.log(res)
    //   this.items = this.usuarios
    //   this.totalRows = this.items.length
    // })
  },
  methods: {
    ...mapActions([
      'getUsuarios', 'deleteUsuario'
    ]),
    usuariosPorGrupo () {
      this.perfil = store.state.perfilUsuario
      let groupBitacora = 0
      for (const group in this.perfil.grupos) {
        if (this.perfil.grupos[group].nombre === 'bitacora') {
          groupBitacora = this.perfil.grupos[group].group_id
        }
      }
      this.getUsuarios(groupBitacora).then((res) => {
        this.items = this.usuarios
        this.totalRows = this.items.length
      })
    },
    info (item, index, button) {
      this.infoModal.title = 'Documentos Transporte ' + item.nro_transporte
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal () {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    hideModal () {
      this.$refs[this.infoModal.id].hide()
    },
    infoDeleteModal (item, index, button) {
      this.deleteModal.title = 'Eliminar Usuario ' + item.username
      this.deleteModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.deleteModal.id, button)
    },
    resetDeleteModal () {
      this.deleteModal.title = ''
      this.deleteModal.content = ''
    },
    hideDeleteModal () {
      this.$refs[this.deleteModal.id].hide()
    },
    infoEditModalModal (item, index, button) {
      this.editModal.title = 'Editar Usuario ' + item.username
      this.editModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.editModal.id, button)
    },
    resetEditModalModal () {
      this.editModal.title = ''
      this.editModal.content = ''
    },
    hideEditModalModal () {
      this.$refs[this.editModal.id].hide()
    },
    onFiltered (filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    crearUsuario () {
      console.log('crear usuario')
      this.infoModal.title = 'Crear Usuario'
      this.infoModal.content = ''
      this.$root.$emit('bv::show::modal', this.infoModal.id)
    },
    borrarUsuario (item) {
      const itemJSON = JSON.parse(item)
      console.log(itemJSON.username)
      this.deleteUsuario(itemJSON.username).then((res) => {
        console.log('deleteUsuario ' + res)
        this.hideDeleteModal()
        this.usuariosPorGrupo()
        // this.items = this.usuarios
        // this.totalRows = this.items.length
      })
    },
    makeToast (toast) {
      this.$bvToast.toast(toast.body, {
        title: toast.title,
        variant: toast.variant,
        autoHideDelay: 5000
      })
    }
  },
  computed: {
    ...mapState(['usuarios']),
    sortOptions () {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key }
        })
    }
  }
}

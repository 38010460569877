import store from '@/store'
import { mapActions, mapState } from 'vuex'
// import * as XLSX from 'xlsx/xlsx.mjs'
// import store from '@/store'
// import axios from 'axios'
// import router from '@/router/'
// import PanelEntregaDiaria from '@/components/Layout/Panel_entrega_diaria/PanelEntregaDiariaView.vue'
// import BarChart from '@/components/Layout/Menu/Graficos/BarChart/BarChart.vue'
// import store from '@/store'

export default {
  name: 'CrearUsuariosiew',
  components: {
    // PanelEntregaDiaria
  },
  props: {
  },
  data: function () {
    return {
      analista: store.state.user,
      username: '',
      nombre: '',
      email: '',
      phone: '',
      password: '',
      rol: null,
      options: [
        { value: null, text: '--SELECCIONAR ROL--' },
        { value: 'ROLE_ANALISTA_BITACORA', text: 'Analista' }
      ]
    }
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    ...mapActions('Clientes', [
      'getAllClientes', 'insertClientes'
    ]),
    ...mapActions(['setUsuario', 'getUsuarios']),
    crearUsuario () {
      console.log('crearUsuario')
      console.log(this.usuarios)
      let rolSelect = ''
      if (this.rol === null) {
        rolSelect = 'ROLE_ANALISTA_BITACORA'
      } else {
        rolSelect = this.rol
      }
      const usuarios = []
      const usuario = {
        username: this.username,
        nombre: this.nombre,
        grupo: 'bitacora',
        phone: this.phone,
        rol: rolSelect,
        email: this.email,
        password: this.password,
        application: 'BITACORA'
      }
      usuarios.push(usuario)
      try {
        this.setUsuario(usuario).then((res) => {
          if (this.usuariosUpdate.status === 200) {
            this.perfil = store.state.perfilUsuario
            let groupBitacora = 0
            for (const group in this.perfil.grupos) {
              if (this.perfil.grupos[group].nombre === 'bitacora') {
                groupBitacora = this.perfil.grupos[group].group_id
              }
            }
            this.getUsuarios(groupBitacora).then((res) => {
              console.log('deberia entrar al emit')
              console.log(this.usuarios)
              this.$emit('updateUsuarios', new Date())
            })
          } else if (this.usuariosUpdate.response.status === 403) {
            // console.log(this.usuariosUpdate.response.status)
            const toast = {}
            toast.body = 'No tienes los permisos para ejecutar esta accion.'
            toast.title = this.usuariosUpdate.response.statusText
            toast.variant = 'danger'
            this.makeToast(toast)
          }
          if (this.usuariosUpdate.response !== undefined && this.usuariosUpdate.response.status === 400) {
            const toast = {}
            toast.body = this.usuariosUpdate.response.data
            toast.title = 'Error'
            toast.variant = 'danger'
            this.makeToast(toast)
          }
        })
      } catch {
        const toast = {}
        toast.body = 'Error Server'
        toast.title = 'Error'
        toast.variant = 'danger'
        this.makeToast(toast)
      }
    },
    makeToast (toast) {
      this.$bvToast.toast(toast.body, {
        title: toast.title,
        variant: toast.variant,
        autoHideDelay: 5000
      })
    }
  },
  computed: {
    ...mapState('Clientes', ['clientes']),
    ...mapState(['usuarios', 'usuariosUpdate'])
  }
}

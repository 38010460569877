import { mapActions, mapState } from 'vuex'
// import * as XLSX from 'xlsx/xlsx.mjs'
// import store from '@/store'
// import axios from 'axios'
// import router from '@/router/'
import CrearComentariosView from '@/components/Layout/Comentarios/Crear_comentarios/CrearComentariosView.vue'
import EditarComentariosView from '@/components/Layout/Comentarios/Editar_comentarios/EditarComentariosView.vue'
// import CrearSubComentariosView from '@/components/Layout/Comentarios/Crear_subcomentario/CrearSubcomentarioView.vue'
// import SubcomentariosView from '@/components/Layout/Comentarios/Subcomentarios/SubcomentariosView.vue'
import ComentariosDetalleView from '@/components/Layout/Comentarios_detalle/ComentariosDetalleView.vue'
import FiltroGenericoTablasView from '@/components/Layout/Filtros/Filtro_generico_tablas/FiltroGenericoTablasView.vue'
// import BarChart from '@/components/Layout/Menu/Graficos/BarChart/BarChart.vue'
// import store from '@/store'

export default {
  name: 'ComentariosView',
  components: {
    ComentariosDetalleView,
    CrearComentariosView,
    FiltroGenericoTablasView,
    EditarComentariosView
  },
  props: {
    comentario: null,
    tipo: null
  },
  data: function () {
    return {
      comentariosJson: [],
      items: [
      ],
      fields: [
        // { key: 'fecha', label: 'fecha', sortable: true, class: 'text-center', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'fecha_ruta', label: 'Fecha Ruta', sortable: true, class: 'text-center', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'fecha_comentario', label: 'Fecha Comentario', sortable: true, class: 'text-center', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'nro_cliente', label: 'Nro Cliente', sortable: true, sortDirection: 'desc', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'nro_transporte', label: 'Nombre Transporte', sortable: true, class: 'text-center', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'nombre_cliente', label: 'Nombre Cliente', sortable: true, class: 'text-center', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'nro_comentario', label: 'Nro Comentario', sortable: true, class: 'text-center', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'comentarios', label: 'Comentarios', sortable: true, class: 'text-center', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'nro_documento', label: 'Nro Documento', sortable: true, class: 'text-center', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'analista', label: 'Analista', sortable: true, class: 'text-center', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'tipo_coordinacion', label: 'Tipo Coordinacion', sortable: true, class: 'text-center', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'origen_contacto', label: 'Origen contacto', sortable: true, class: 'text-center', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'tipo_gestion', label: 'Tipo De Gestion', sortable: true, class: 'text-center', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'respuesta', label: 'Respuesta', sortable: true, class: 'text-center', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'motivo_no_entrega', label: 'Motivo No Entrega', sortable: true, class: 'text-center', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'estado', label: 'Estado', sortable: true, class: 'text-center', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'acciones', label: 'Acciones', sortable: true, class: 'text-center', thStyle: { background: '#FF9B00', color: '#ffffff' } }
        // {
        //   key: 'isActive',
        //   label: 'Is Active',
        //   formatter: (value, key, item) => {
        //     return value ? 'Yes' : 'No'
        //   },
        //   sortable: true,
        //   sortByFormatted: true,
        //   filterByFormatted: true
        // },
        // { key: 'comentarios', label: 'Comentarios', thStyle: { background: '#FF9B00', color: '#ffffff' } }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, { value: 100, text: 'Show a lot' }],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'infoComentario-modal',
        title: '',
        content: ''
      },
      clienteModal: {
        id: 'clienteDetalle-modal',
        title: '',
        content: ''
      },
      editarComentarioModal: {
        id: 'editarComentario-modal',
        title: '',
        content: ''
      },
      activeColor: 'arial',
      fontSize: 13,
      backgroundColor: '#91c27d',
      tipoFiltro: '',
      dataCrearComentarios: {},
      updateComentarios: '',
      clientes: [],
      transportes: [],
      estados: [],
      camiones: [],
      cd: [],
      itemsAux: [],
      filtro: null,
      datosClienteObject: ''
    }
  },
  watch: {
    comentario: function (val) {
      // console.log('observador comentario')
      switch (this.tipo) {
        case 'byTransporte':
          // console.log('ByTransporte comentario')
          // console.log(this.comentario)
          // esto se realiza ya que esta variable en los comentarios se llama id_entrega_diaria, pero cuando viene desde el panel de entrega diaria se llama id_transporte
          if (this.comentario.id_transporte === undefined) {
            this.comentario.id_transporte = this.comentario.id_entrega_diaria
          }
          this.getcomentariosByIdEntregaDiaria(this.comentario.id_transporte).then((res) => {
            // console.log(this.comentarios)
            this.items = []
            this.items = this.comentarios
            for (const item in this.items) {
              this.items[item].fecha_ruta = this.items[item].fecha_ruta.replace('T', ' ').substring(0, 18)
              this.items[item].fecha_comentario = this.items[item].fecha_comentario.replace('T', ' ').substring(0, 18)
            }
            this.totalRows = this.items.length
            this.itemsAux = this.items
            this.listasFiltros()
          })
          break
        case 'byAll':
          // console.log('es byAll comentario')
          this.getcomentariosAllByPeriodo(this.periodoActual()).then((res) => {
            this.items = []
            this.items = this.comentarios
            for (const item in this.items) {
              this.items[item].fecha_ruta = this.items[item].fecha_ruta.replace('T', ' ').substring(0, 18)
              this.items[item].fecha_comentario = this.items[item].fecha_comentario.replace('T', ' ').substring(0, 18)
            }
            this.totalRows = this.items.length
            this.itemsAux = this.items
            this.listasFiltros()
          })
          break
      }
      this.totalRows = this.items.length
    },
    tipo: function (val) {
      // console.log('observador tipo')
      switch (this.tipo) {
        case 'byAll':
          // console.log('es byAll tipo')
          this.getcomentariosAllByPeriodo(this.periodoActual()).then((res) => {
            this.items = []
            this.items = this.comentarios
            for (const item in this.items) {
              this.items[item].fecha_ruta = this.items[item].fecha_ruta.replace('T', ' ').substring(0, 18)
              this.items[item].fecha_comentario = this.items[item].fecha_comentario.replace('T', ' ').substring(0, 18)
            }
            this.totalRows = this.items.length
            this.itemsAux = this.items
            this.listasFiltros()
          })
          break
        case 'byDocumento':
          this.comentariosJson = JSON.parse(this.comentario)
          this.getcomentariosByDocumento(this.comentariosJson.nro_documento).then((res) => {
            this.items = []
            this.items = this.comentarios
            for (const item in this.items) {
              this.items[item].fecha_ruta = this.items[item].fecha_ruta.replace('T', ' ').substring(0, 18)
              this.items[item].fecha_comentario = this.items[item].fecha_comentario.replace('T', ' ').substring(0, 18)
            }
            this.totalRows = this.items.length
            this.itemsAux = this.items
            this.listasFiltros()
          })
          break
      }
      this.totalRows = this.items.length
      // this.listasFiltros()
    },
    updateComentarios: function (val) {
      this.hideEditarComentarioModal()
      // console.log(this.tipo)
      switch (this.tipo) {
        case 'byAll':
          console.log('es byAll')
          this.getcomentariosAllByPeriodo(this.periodoActual()).then((res) => {
            this.items = []
            this.items = this.comentarios
            for (const item in this.items) {
              this.items[item].fecha_ruta = this.items[item].fecha_ruta.replace('T', ' ').substring(0, 18)
              this.items[item].fecha_comentario = this.items[item].fecha_comentario.replace('T', ' ').substring(0, 18)
            }
            this.itemsAux = this.items
          })
          break
        case 'byDocumento':
          console.log('es byAll')
          this.comentariosJson = JSON.parse(this.comentario)
          this.getcomentariosByDocumento(this.comentariosJson.nro_documento).then((res) => {
            this.items = []
            this.items = this.comentarios
            for (const item in this.items) {
              this.items[item].fecha_ruta = this.items[item].fecha_ruta.replace('T', ' ').substring(0, 18)
              this.items[item].fecha_comentario = this.items[item].fecha_comentario.replace('T', ' ').substring(0, 18)
            }
            this.itemsAux = this.items
            this.listasFiltros()
          })
          break
        case 'byTransporte':
          // console.log('ByTransporte comentario')
          // console.log(this.comentario)
          // esto se realiza ya que esta variable en los comentarios se llama id_entrega_diaria, pero cuando viene desde el panel de entrega diaria se llama id_transporte
          if (this.comentario.id_transporte === undefined) {
            this.comentario.id_transporte = this.comentario.id_entrega_diaria
          }
          this.getcomentariosByIdEntregaDiaria(this.comentario.id_transporte).then((res) => {
            // console.log(this.comentarios)
            this.items = []
            this.items = this.comentarios
            for (const item in this.items) {
              this.items[item].fecha_ruta = this.items[item].fecha_ruta.replace('T', ' ').substring(0, 18)
              this.items[item].fecha_comentario = this.items[item].fecha_comentario.replace('T', ' ').substring(0, 18)
            }
            this.totalRows = this.items.length
            this.itemsAux = this.items
            this.listasFiltros()
          })
          break
      }
      this.totalRows = this.items.length
      // this.hideModal()
      // this.getcomentariosAll().then((res) => {
      //   this.items = []
      //   this.items = this.comentarios
      //   for (const item in this.items) {
      //     this.items[item].fecha_ruta = this.items[item].fecha_ruta.replace('T', ' ').substring(0, 18)
      //     this.items[item].fecha_comentario = this.items[item].fecha_comentario.replace('T', ' ').substring(0, 18)
      //   }
      //   this.itemsAux = this.items
      //   this.totalRows = this.items.length
      // })
    },
    filtro: function (val) {
      this.items = this.itemsAux
      this.filtrarCampos()
      // console.log(this.filtro)
      let periodos = {}
      if (this.filtro.periodos.desde !== undefined && this.filtro.periodos.hasta !== undefined) {
        periodos = {
          desde: this.filtro.periodos.desde + ' 00:00:00',
          hasta: this.filtro.periodos.hasta + ' 23:59:59'
        }
      }
      this.getcomentariosAllByPeriodo(periodos).then((res) => {
        // console.log(this.comentarios)
        this.items = []
        this.items = this.comentarios
        for (const item in this.items) {
          this.items[item].fecha_ruta = this.items[item].fecha_ruta.replace('T', ' ').substring(0, 18)
          this.items[item].fecha_comentario = this.items[item].fecha_comentario.replace('T', ' ').substring(0, 18)
        }
        this.itemsAux = this.items
        // console.log(this.items)
        // this.listasFiltros()
        this.filtrarCampos()
      })
    }
  },
  mounted () {
    // console.log(this.comentariosJson.nro_documento)
    switch (this.tipo) {
      case 'byAll':
        // console.log('es byAll mounted')
        this.getcomentariosAllByPeriodo(this.periodoActual()).then((res) => {
          this.items = []
          this.items = this.comentarios
          for (const item in this.items) {
            this.items[item].fecha_ruta = this.items[item].fecha_ruta.replace('T', ' ').substring(0, 18)
            this.items[item].fecha_comentario = this.items[item].fecha_comentario.replace('T', ' ').substring(0, 18)
          }
          this.itemsAux = this.items
        })
        break
      case 'byDocumento':
        this.comentariosJson = JSON.parse(this.comentario)
        this.getcomentariosByDocumento(this.comentariosJson.nro_documento).then((res) => {
          this.items = []
          this.items = this.comentarios
          for (const item in this.items) {
            this.items[item].fecha_ruta = this.items[item].fecha_ruta.replace('T', ' ').substring(0, 18)
            this.items[item].fecha_comentario = this.items[item].fecha_comentario.replace('T', ' ').substring(0, 18)
          }
          this.itemsAux = this.items
          this.listasFiltros()
        })
        break
    }
    this.totalRows = this.items.length
    // this.listasFiltros()
  },
  methods: {
    ...mapActions('Comentarios', [
      'getcomentariosByDocumento', 'getcomentariosByTransporte', 'getcomentariosAll', 'getcomentariosAllByPeriodo', 'getcomentariosByIdEntregaDiaria'
    ]),
    ...mapActions('Clientes', [
      'getClienteById'
    ]),
    info (item, index, button) {
      // console.log(item)
      if (item.nro_documento === 0) {
        this.infoModal.title = 'Comentario detalle: GENERAL'
      } else {
        this.infoModal.title = 'Comentario detalle: ' + item.nro_documento
      }
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal () {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    editarComentario (item, index, button) {
      this.editarComentarioModal.title = 'Editar Comentario'
      this.editarComentarioModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.editarComentarioModal.id, button)
    },
    hideEditarComentarioModal () {
      this.$refs[this.editarComentarioModal.id].hide()
    },
    onFiltered (filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    hideModal () {
      this.$refs[this.infoModal.id].hide()
      console.log(JSON.parse(this.infoModal.content))
      console.log(this.filtro)
      // this.getcomentariosByIdEntregaDiaria(JSON.parse(this.infoModal.content).id_entrega_diaria
      // ).then((res) => {
      //   console.log(res)
      //   // this.items = []
      //   console.log(this.comentarios)
      //   this.items = this.comentarios
      //   for (const item in this.items) {
      //     this.items[item].fecha_ruta = this.items[item].fecha_ruta.replace('T', ' ').substring(0, 18)
      //     this.items[item].fecha_comentario = this.items[item].fecha_comentario.replace('T', ' ').substring(0, 18)
      //   }
      //   this.totalRows = this.items.length
      // })
      let periodos = {}
      if (this.filtro !== undefined && this.filtro !== null) {
        console.log('no son null')
        periodos = {
          desde: this.filtro.periodos.desde + ' 00:00:00',
          hasta: this.filtro.periodos.hasta + ' 23:59:59'
        }
      } else {
        periodos = this.periodoActual()
      }
      console.log(periodos)
      console.log(this.tipo)
      switch (this.tipo) {
        case 'byTransporte':
          console.log('ByTransporte comentario')
          console.log(this.comentario)
          // esto se realiza ya que esta variable en los comentarios se llama id_entrega_diaria, pero cuando viene desde el panel de entrega diaria se llama id_transporte
          if (this.comentario.id_transporte === undefined) {
            this.comentario.id_transporte = this.comentario.id_entrega_diaria
          }
          this.getcomentariosByIdEntregaDiaria(this.comentario.id_transporte).then((res) => {
            console.log(res)
            console.log(this.comentarios)
            this.items = []
            this.items = this.comentarios
            for (const item in this.items) {
              this.items[item].fecha_ruta = this.items[item].fecha_ruta.replace('T', ' ').substring(0, 18)
              this.items[item].fecha_comentario = this.items[item].fecha_comentario.replace('T', ' ').substring(0, 18)
            }
            this.totalRows = this.items.length
            this.itemsAux = this.items
            this.listasFiltros()
          })
          break
        case 'byAll':
          // console.log('es byAll comentario')
          this.getcomentariosAllByPeriodo(this.periodoActual()).then((res) => {
            this.items = []
            this.items = this.comentarios
            for (const item in this.items) {
              this.items[item].fecha_ruta = this.items[item].fecha_ruta.replace('T', ' ').substring(0, 18)
              this.items[item].fecha_comentario = this.items[item].fecha_comentario.replace('T', ' ').substring(0, 18)
            }
            this.totalRows = this.items.length
            this.itemsAux = this.items
            this.listasFiltros()
          })
          break
      }
      this.totalRows = this.items.length
    },
    listasFiltros () {
      // console.log('listasFiltros')
      this.transportes = []
      this.clientes = []
      this.estados = []
      this.camiones = []
      for (const item in this.items) {
        // console.log(this.items[item])
        if (this.items[item].nro_transporte !== null) {
          this.transportes.push(this.items[item].nro_transporte.toString())
        }
        if (this.items[item].nro_cliente !== null) {
          this.clientes.push(this.items[item].nro_cliente.toString())
        }
        if (this.items[item].estado !== null) {
          this.estados.push(this.items[item].estado.toString())
        }
        // this.camiones.push(this.items[item].nro_camion.toString())
        // this.cd.push(this.items[item].cd.toString())
      }
      for (let i = this.clientes.length - 1; i >= 0; i--) {
        if (this.clientes.indexOf(this.clientes[i]) !== i) this.clientes.splice(i, 1)
      }
      for (let i = this.transportes.length - 1; i >= 0; i--) {
        if (this.transportes.indexOf(this.transportes[i]) !== i) this.transportes.splice(i, 1)
      }
      for (let i = this.estados.length - 1; i >= 0; i--) {
        if (this.estados.indexOf(this.estados[i]) !== i) this.estados.splice(i, 1)
      }
    },
    filtrarCampos () {
      const clientesFilter = []
      const transportesFilter = []
      const estadosFilter = []
      if (this.filtro.clientes !== null) clientesFilter.push(this.items.filter(clientesfilter => clientesfilter.nro_cliente === parseInt(this.filtro.clientes)))
      if (this.filtro.transportes !== null) transportesFilter.push(this.items.filter(transportefilter => transportefilter.nro_transporte === parseInt(this.filtro.transportes)))
      if (this.filtro.estados !== null) {
        estadosFilter.push(this.items.filter(estadosfilter => estadosfilter.estado === this.filtro.estados))
      }
      const filtrosConcat = clientesFilter.concat(transportesFilter).concat(estadosFilter)
      this.items = []
      for (const filtroConcat in filtrosConcat) {
        for (const filtroConcatsub in filtrosConcat[filtroConcat]) {
          this.items.push(filtrosConcat[filtroConcat][filtroConcatsub])
        }
      }
      // console.log(this.items)
      // console.log(clientesFilter)
      // console.log(transportesFilter)
      // console.log(estadosFilter)
      // console.log(cdFilter)
      // console.log(filtrosConcat)
      // console.log(this.items)
      // console.log(this.filtro.estados)
      // console.log(this.filtro.transportes)
      // console.log(this.filtro.clientes)
      if (this.items.length === 0 && this.filtro.estados === null && this.filtro.transportes === null && this.filtro.clientes === null) {
        this.items = this.itemsAux
      }
      this.totalRows = this.items.length
    },
    clienteOpenModal (item, index, button) {
      this.clienteModal.title = 'Cliente'
      this.clienteModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.clienteModal.id, button)
    },
    resetClienteModal () {
      this.clienteModal.title = ''
      this.clienteModal.content = ''
    },
    datosCliete (item, index, button) {
      // console.log(item.nro_cliente)
      this.getClienteById(item.nro_cliente).then((res) => {
        this.datosClienteObject = this.cliente
        this.clienteOpenModal(item, index, button)
      })
    },
    periodoActual () {
      const date = new Date()
      const fechaActualDesde = `${
        date.getFullYear().toString().padStart(4, '0')}-${
          (date.getMonth() + 1).toString().padStart(2, '0')}-${
            date.getDate().toString().padStart(2, '0')} ${
              '00'}:${
                '00'}:${
                  '00'}`
      const fechaActualHasta = `${
        date.getFullYear().toString().padStart(4, '0')}-${
          (date.getMonth() + 1).toString().padStart(2, '0')}-${
            date.getDate().toString().padStart(2, '0')} ${
              date.getHours().toString().padStart(2, '0')}:${
                date.getMinutes().toString().padStart(2, '0')}:${
                  date.getSeconds().toString().padStart(2, '0')}`
      // console.log(fechaActualDesde)
      // console.log(fechaActualHasta)
      const periodos = {
        desde: fechaActualDesde,
        hasta: fechaActualHasta
      }
      return periodos
    }
  },
  computed: {
    ...mapState('Comentarios', ['comentarios']),
    ...mapState('Clientes', ['cliente']),
    sortOptions () {
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key }
        })
    }
  }
}

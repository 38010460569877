
export default {
  name: 'WebsocketView',
  components: {
  },
  props: {
  },
  data: function () {
    return {
      evento: null,
      socket: null
    }
  },
  watch: {
    evento: function (val) {
      this.makeToast(this.evento)
    }
  },
  mounted () {
    const self = this
    this.socket = new WebSocket('wss://app.bitacora.v2.dmetrix.cl/api/bitacora/ws')
    this.socket.onopen = function (event) {
      console.log('Conexión WebSocket abierta')
      // console.log(event)
      // const test = {
      //   usuario: 'ccampos',
      //   mesasageRequest: 'test websocket',
      //   type: 'test'
      // }
      // socket.send(JSON.stringify(test))
    }
    this.socket.onmessage = function (event) {
      // console.log("Mensaje recibido:", event);
      // console.log("Mensaje recibido:", event.data);
      // const data = JSON.parse(event.data)
      const toast = {}
      toast.body = event.data
      console.log(event.data)
      if (event.data.includes('Cargando archivo')) {
        toast.title = 'Cargando archivo...'
        toast.variant = 'warning'
      } else if (event.data.includes('Carga con exitosa')) {
        toast.title = 'Exito'
        toast.variant = 'success'
      }
      self.evento = toast
    }
  },
  methods: {
    sendMessageWebSocket (data) {
      this.socket.send(data)
    },
    makeToast (toast) {
      this.$bvToast.toast(toast.body, {
        title: toast.title,
        variant: toast.variant,
        autoHideDelay: 5000
      })
    }
  },
  computed: {
    // ...mapState('AdministradorFlota', ['transportes'])
  }
}

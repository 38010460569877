import store from '@/store'
import { mapActions, mapState } from 'vuex'
// import * as XLSX from 'xlsx/xlsx.mjs'
// import store from '@/store'
// import axios from 'axios'
// import router from '@/router/'
// import PanelEntregaDiaria from '@/components/Layout/Panel_entrega_diaria/PanelEntregaDiariaView.vue'
// import BarChart from '@/components/Layout/Menu/Graficos/BarChart/BarChart.vue'
// import store from '@/store'

export default {
  name: 'EditarUsuariosiew',
  components: {
    // PanelEntregaDiaria
  },
  props: {
    usuario: null
  },
  data: function () {
    return {
      nroClienteSap: 0,
      razonSocial: '',
      nombreFantasia: '',
      telefono: '',
      contacto: '',
      direccionDespacho: '',
      territorioVenta: '',
      clienteFoco: '',
      canalVenta: '',
      analista: store.state.user,
      username: '',
      nombre: '',
      email: '',
      phone: '',
      password: '',
      rol: null,
      options: [
        { value: null, text: '--SELECCIONAR ROL--' },
        { value: 'ROLE_ANALISTA_BITACORA', text: 'Analista' }
      ],
      userJSON: {}
    }
  },
  watch: {
  },
  mounted () {
    this.userJSON = JSON.parse(this.usuario)
    console.log(this.userJSON)
    this.username = this.userJSON.username
    this.nombre = this.userJSON.nombre
    this.email = this.userJSON.email
    this.phone = this.userJSON.phone
    this.rol = this.userJSON.roles
  },
  methods: {
    ...mapActions('Clientes', [
      'getAllClientes', 'insertClientes'
    ]),
    ...mapActions(['updateUsuario']),
    editarUsuario () {
      console.log('editarUsuario')
      const usuario = {
        username: this.username,
        nombre: this.nombre,
        email: this.email,
        phone: this.phone,
        rol: this.rol,
        grupo: this.userJSON.grupos
      }
      console.log(usuario)
      this.updateUsuario(usuario).then((res) => {
        this.$emit('updateUsuarios', this.usuariosUpdate)
      })
    }
  },
  computed: {
    ...mapState(['usuariosUpdate'])
  }
}

import { render, staticRenderFns } from "@/components/Layout/Comentarios/Editar_comentarios/Editar_comentarios.html?vue&type=template&id=533ec8c9&scoped=true&"
import script from "@/components/Layout/Comentarios/Editar_comentarios/Editar_comentarios.js?vue&type=script&lang=js&"
export * from "@/components/Layout/Comentarios/Editar_comentarios/Editar_comentarios.js?vue&type=script&lang=js&"
import style0 from "@/components/Layout/Comentarios/Editar_comentarios/Editar_comentarios.css?vue&type=style&index=0&id=533ec8c9&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "533ec8c9",
  null
  
)

export default component.exports
// import { mapActions, mapState } from 'vuex'
// import * as XLSX from 'xlsx/xlsx.mjs'
// import store from '@/store'
// import axios from 'axios'
// import router from '@/router/'
// import PanelEntregaDiaria from '@/components/Layout/Panel_entrega_diaria/PanelEntregaDiariaView.vue'
// import BarChart from '@/components/Layout/Menu/Graficos/BarChart/BarChart.vue'
// import store from '@/store'

export default {
  name: 'FiltroReportesView',
  components: {
  },
  props: {
    clientes: null,
    transportes: null,
    estados: null,
    camiones: null,
    cd: null,
    fechaRutaEnable: null,
    filtroNroClienteEnable: null,
    filtroNroTransporteEnable: null,
    filtroEstadosEnable: null
  },
  data: function () {
    return {
      options: ['Apple', 'Orange', 'Banana', 'Lime', 'Peach', 'Chocolate', 'Strawberry'],
      value: [],
      optionsClientes: ['Andina', 'Sopraval', 'Soprole'],
      // valueClientes: [],
      valueClientes: null,
      optionsTransportes: ['1', '2', '3'],
      // valueTransportes: [],
      valueTransportes: null,
      optionsEstados: ['Realizado', 'No Realizado'],
      // valueEstados: [],
      valueEstados: null,
      optionsCamiones: ['cam 1', 'cam 2', 'cam 3'],
      valueCamiones: [],
      optionsCD: ['Puente Alto CD', 'Quinta Normal CD'],
      valueCD: [],
      fechaRutaDesde: '',
      fechaRutaHasta: '',
      fechaComentario: '',
      selectNroCliete: 0,
      selectNroTransporte: 0,
      selectNroCamion: 0,
      selectCD: '',
      // SelectEstado: '',
      SelectEstado: null,
      optionsEstado: [
        { value: null, text: 'Seleccionar estado' },
        { value: '', text: 'Sin gestionar' },
        { value: 'en gestion', text: 'En gestion' },
        // { value: { C: '3PO' }, text: 'This is an option with object value' },
        { value: 'concluido', text: 'Concluido' }
      ]
    }
  },
  watch: {
    clientes: function (val) {
      // console.log('entra en observador clientes')
      // console.log(this.clientes)
      this.optionsClientes = []
      this.optionsClientes = this.clientes
      // this.valueClientes = []
    },
    transportes: function (val) {
      // console.log('entra en observador transportes')
      // console.log(this.transportes)
      this.optionsTransportes = []
      this.optionsTransportes = this.transportes
      // this.valueTransportes = []
    },
    estados: function (val) {
      // console.log('entra en observador estados')
      // console.log(this.estados)
      this.optionsEstados = []
      this.optionsEstados = this.estados
      // this.valueEstados = []
    },
    camiones: function (val) {
      // console.log('entra en observador camiones')
      // console.log(this.camiones)
      this.optionsCamiones = []
      this.optionsCamiones = this.camiones
      // this.valueCamiones = []
    },
    cd: function (val) {
      // console.log('entra en observador cd')
      // console.log(this.cd)
      this.optionsCD = []
      this.optionsCD = this.cd
      // this.valueCD = []
    }
  },
  mounted () {
    if (this.transportes !== null) {
      this.optionsTransportes = this.transportes
    }
    if (this.clientes !== null) {
      this.optionsClientes = this.clientes
    }
    if (this.estados !== null) {
      this.optionsEstados = this.estados
    }
    if (this.camiones !== null) {
      this.optionsCamiones = this.camiones
    }
    if (this.cd !== null) {
      this.optionsCD = this.cd
    }
  },
  methods: {
    // ...mapActions('AdministradorFlota', [
    //   'getTransportes', 'getConductores', 'getAyudantes', 'getCamiones'
    // ])
    filtrar () {
      const filtro = {}
      filtro.transportes = this.valueTransportes
      filtro.clientes = this.valueClientes
      filtro.estados = this.valueEstados
      filtro.camiones = this.valueCamiones
      filtro.cd = this.valueCD
      // console.log('fecha desde null')
      filtro.periodos = {}
      if (this.fechaRutaDesde !== null && this.fechaRutaDesde !== undefined && this.fechaRutaDesde !== '') {
        filtro.periodos.desde = this.fechaRutaDesde
      }
      if (this.fechaRutaHasta !== null && this.fechaRutaHasta !== undefined && this.fechaRutaHasta !== '') {
        filtro.periodos.hasta = this.fechaRutaHasta
      }
      // console.log('filtrar')
      console.log(filtro)
      this.$emit('filtro', filtro)
      // this.fechaRutaDesde = ''
      // this.fechaRutaHasta = ''
    }
  },
  computed: {
    // ...mapState('AdministradorFlota', ['transportes'])
    availableOptions () {
      return this.options.filter(opt => this.value.indexOf(opt) === -1)
    },
    availableOptionsClientes () {
      return this.optionsClientes.filter(opt => this.value.indexOf(opt) === -1)
    },
    availableOptionsTransportes () {
      return this.optionsTransportes.filter(opt => this.value.indexOf(opt) === -1)
    },
    availableOptionsEstados () {
      return this.optionsEstados.filter(opt => this.value.indexOf(opt) === -1)
    },
    availableOptionsCamiones () {
      return this.optionsCamiones.filter(opt => this.value.indexOf(opt) === -1)
    },
    availableOptionsCD () {
      return this.optionsCD.filter(opt => this.value.indexOf(opt) === -1)
    },
    fechaRutaDesdeSelected () {
      return this.fechaRutaDesde.length > 0
    },
    fechaRutaHastaSelected () {
      return this.fechaRutaHasta.length > 0
    }
  }
}

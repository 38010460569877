import { mapActions, mapState } from 'vuex'
// import * as XLSX from 'xlsx/xlsx.mjs'
// import store from '@/store'
// import axios from 'axios'
// import router from '@/router/'
import Comentarios from '@/components/Layout/Comentarios/ComentariosView.vue'
import CrearComentariosView from '@/components/Layout/Comentarios/Crear_comentarios/CrearComentariosView.vue'
// import BarChart from '@/components/Layout/Menu/Graficos/BarChart/BarChart.vue'
// import store from '@/store'

export default {
  name: 'DocumentosPedidoView',
  components: {
    Comentarios,
    CrearComentariosView
  },
  props: {
    entregaDiaria: null
  },
  data: function () {
    return {
      entregaDiariaJson: [],
      items: [
      ],
      fields: [
        { key: 'fecha', label: 'fecha', sortable: true, class: 'text-center', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'nro_cliente', label: 'Nro Cliente', sortable: true, sortDirection: 'desc', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'nombre_cliente', label: 'Nombre Cliente', sortable: true, class: 'text-center', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'nro_transporte', label: 'Nro Transporte', sortable: true, class: 'text-center', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'nro_documento', label: 'Nro Documento', sortable: true, class: 'text-center', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'nro_pedido', label: 'Nro Pedido', sortable: true, class: 'text-center', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'nombre_conductor', label: 'Nombre Conductor', sortable: true, class: 'text-center', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'cantidad_uc', label: 'Cantidad UC', sortable: true, class: 'text-center', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'estado', label: 'Estado', sortable: true, class: 'text-center', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'vuelta', label: 'Vuelta', sortable: true, class: 'text-center', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'vuelta', label: 'Vuelta', sortable: true, class: 'text-center', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'detalle', label: 'Detalle', sortable: true, class: 'text-center', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'comentarios', label: 'Comentarios', thStyle: { background: '#FF9B00', color: '#ffffff' } }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, { value: 100, text: 'Show a lot' }],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: ''
      },
      activeColor: 'arial',
      fontSize: 13,
      backgroundColor: '#91c27d',
      crearComentarioModal: {
        id: 'crearComentarioDocumentoPedido-modal',
        title: '',
        content: ''
      },
      updateComentarios: {}
    }
  },
  watch: {
    updateComentarios: function (val) {
      // console.log('observador entregaDiaria')
      this.hideComentarioModal()
      this.entregaDiariaJson = JSON.parse(this.entregaDiaria)
      // console.log(this.entregaDiariaJson)
      this.getDocumentosPedidoByIdTransporte(this.entregaDiariaJson.id_transporte).then((res) => {
        console.log(res)
        this.items = []
        this.items = this.documentosPedido
        this.totalRows = this.items.length
      })
      this.totalRows = this.items.length
    }
  },
  mounted () {
    this.entregaDiariaJson = JSON.parse(this.entregaDiaria)
    console.log(this.entregaDiariaJson)
    this.getDocumentosPedidoByIdTransporte(this.entregaDiariaJson.id_transporte).then((res) => {
      console.log(res)
      this.items = []
      this.items = this.documentosPedido
      this.totalRows = this.items.length
    })
    this.totalRows = this.items.length
  },
  methods: {
    ...mapActions('DocumentosPedido', [
      'getDocumentosPedido', 'getDocumentosPedidoByIdTransporte'
    ]),
    info (item, index, button) {
      this.infoModal.title = 'Comentarios Documento ' + item.nro_documento
      this.infoModal.content = JSON.stringify(item, null, 2)
      const data = this.infoModal.content
      const dataJSON = JSON.parse(data)
      // console.log(dataJSON)
      this.entregaDiariaJson.nro_documento = dataJSON.nro_documento
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal () {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered (filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    crearComentarioView (data) {
      // console.log(data)
      const dataJSON = JSON.parse(data)
      // console.log(dataJSON)
      this.entregaDiariaJson.nro_documento = dataJSON.nro_documento
      this.crearComentarioModal.title = 'Crear Comentario General'
      // this.crearComentarioModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.crearComentarioModal.id)
    },
    resetComentarioModal () {
      this.crearComentarioModal.title = ''
      this.crearComentarioModal.content = ''
    },
    hideComentarioModal () {
      this.$refs[this.infoModal.id].hide()
    },
    updateComentariosDocumentos () {
      this.$emit('updateComentarios', this.comentarios)
    }
  },
  computed: {
    ...mapState('DocumentosPedido', ['documentosPedido']),
    sortOptions () {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key }
        })
    }
  }
}

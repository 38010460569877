import { mapActions, mapState } from 'vuex'
import * as XLSX from 'xlsx/xlsx.mjs'
// import store from '@/store'
// import axios from 'axios'
// import router from '@/router/'
import CrearClientesView from '@/components/Layout/Clientes/Administracion_clientes/Crear_clientes/CrearClientesView.vue'
import EditarClientesView from '@/components/Layout/Clientes/Administracion_clientes/Editar_clientes/EditarClientesView.vue'
import CargarClientesView from '@/components/Layout/Clientes/Administracion_clientes/Cargar_clientes/CargarClientesView.vue'
// import BarChart from '@/components/Layout/Menu/Graficos/BarChart/BarChart.vue'
// import store from '@/store'

export default {
  name: 'AdministracionClientesView',
  components: {
    CrearClientesView,
    CargarClientesView,
    EditarClientesView
  },
  props: {
  },
  data: function () {
    return {
      items: [],
      fields: [
        { key: 'nro_cliente_sap', label: 'Nro Cliente Sap', sortable: true, class: 'text-center', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'razon_social', label: 'Razon Social', sortable: true, sortDirection: 'desc', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'nombre_fantasia', label: 'Nombre Fantasia', sortable: true, class: 'text-center', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'telefono', label: 'Telefono', sortable: true, class: 'text-center', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'contacto', label: 'Contacto', sortable: true, class: 'text-center', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'direccion_despacho', label: 'Direccion Despacho', sortable: true, class: 'text-center', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'territorio_venta', label: 'Territorio Venta', sortable: true, class: 'text-center', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'cliente_foco', label: 'Cliente Foco', sortable: true, class: 'text-center', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'canal_venta', label: 'Canal Venta', sortable: true, class: 'text-center', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'analista_ma', label: 'Analista', sortable: true, class: 'text-center', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'acciones', label: 'Acciones', sortable: true, class: 'text-center', thStyle: { background: '#FF9B00', color: '#ffffff' } }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, { value: 100, text: 'Show a lot' }],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: ''
      },
      deleteModal: {
        id: 'delete-modal',
        title: '',
        content: ''
      },
      editModal: {
        id: 'edit-modal',
        title: '',
        content: ''
      },
      activeColor: 'arial',
      fontSize: 13,
      backgroundColor: '#91c27d',
      updateClientes: '',
      show: false,
      page: 1,
      filterRazonSocial: '',
      filterNroClienteSap: null
    }
  },
  watch: {
    updateClientes: function (val) {
      // if (this.updateClientes !== this.items) {
      // }
      console.log('observador updateClientes')
      this.resetInfoModal()
      this.hideModal()
      this.hideEditModalModal()
      // this.$refs[this.infoModal.id].hide()
      // this.items = this.updateClientes
      // this.totalRows = this.items.length
      const page = {
        page: 0,
        size: this.perPage
      }
      this.getAllClientesPage(page).then((res) => {
        this.items = this.clientePage.content
        this.totalRows = this.items.length
        const toast = {}
        toast.body = 'Cliente Creado Con Exito'
        toast.title = 'Tabla Clientes Actualizada'
        toast.variant = 'success'
        this.makeToast(toast)
        this.hideEditModalModal()
      })
    },
    filterRazonSocial: function (val) {
      if (this.filterRazonSocial === '') {
        const page = {
          page: 0,
          size: this.perPage
        }
        this.getAllClientesPage(page).then((res) => {
          // console.log('getAllClientesPage ' + res)
          this.items = this.clientePage.content
          this.totalRows = this.items.length
        })
      }
    },
    filterNroClienteSap: function (val) {
      if (this.filterNroClienteSap === 0 || this.filterNroClienteSap === '' || this.filterNroClienteSap === null) {
        const page = {
          page: 0,
          size: this.perPage
        }
        this.getAllClientesPage(page).then((res) => {
          // console.log('getAllClientesPage ' + res)
          this.items = this.clientePage.content
          this.totalRows = this.items.length
        })
      }
    },
    perPage: function (val) {
      const page = {
        page: 0,
        size: this.perPage
      }
      this.getAllClientesPage(page).then((res) => {
        // console.log('getAllClientesPage ' + res)
        this.items = this.clientePage.content
        this.totalRows = this.items.length
      })
    }
  },
  mounted () {
    const page = {
      page: 0,
      size: this.perPage
    }
    this.getAllClientesPage(page).then((res) => {
      this.items = this.clientePage.content
      this.totalRows = this.items.length
    })
  },
  methods: {
    ...mapActions('Clientes', [
      'getAllClientes', 'insertClientes', 'deleteCliente', 'getAllClientesPage', 'getAllClientesPageByRazonSocial', 'getAllClientesPageByNroClienteSap'
    ]),
    handlePageChange (value) {
      this.page = value
      const page = {
        page: (this.page - 1),
        size: this.perPage
      }
      if (this.filterRazonSocial !== '') {
        page.filter = this.filterRazonSocial
        this.getAllClientesPageByRazonSocial(page).then((res) => {
          // console.log('getAllClientesPageByRazonSocial ' + res)
          this.items = this.clientePage.content
          this.totalRows = this.items.length
        })
      } else {
        this.getAllClientesPage(page).then((res) => {
          // console.log('getAllClientesPage ' + res)
          this.items = this.clientePage.content
          this.totalRows = this.items.length
        })
      }
    },
    filterClientes (data) {
      const page = {
        page: (this.page - 1),
        size: this.perPage,
        filter: this.filterNroClienteSap
      }
      console.log(page)
      this.getAllClientesPageByNroClienteSap(page).then((res) => {
        // console.log('getAllClientesPageByRazonSocial ' + res)
        this.items = this.clientePage.content
        this.totalRows = this.items.length
      })
    },
    paginacion (page) {
    },
    info (item, index, button) {
      this.infoModal.title = 'Documentos Transporte ' + item.nro_transporte
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal () {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    hideModal () {
      this.$refs[this.infoModal.id].hide()
    },
    infoDeleteModal (item, index, button) {
      this.deleteModal.title = 'Eliminar Cliente ' + item.nombre_fantasia
      this.deleteModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.deleteModal.id, button)
    },
    resetDeleteModal () {
      this.deleteModal.title = ''
      this.deleteModal.content = ''
    },
    hideDeleteModal () {
      this.$refs[this.deleteModal.id].hide()
    },
    infoEditModalModal (item, index, button) {
      this.editModal.title = 'Editar Cliente ' + item.nombre_fantasia
      this.editModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.editModal.id, button)
    },
    resetEditModalModal () {
      this.editModal.title = ''
      this.editModal.content = ''
    },
    hideEditModalModal () {
      this.$refs[this.editModal.id].hide()
    },
    onFiltered (filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    obtenerClientes () {
      this.show = true
      // this.getAllClientes().then((res) => {
      //   console.log('getAllClientes ' + res)
      //   this.items = this.clientes
      //   this.totalRows = this.items.length
      //   this.show = false
      // })
      const page = {
        page: 0,
        size: this.perPage
      }
      this.getAllClientesPage(page).then((res) => {
        this.items = this.clientePage.content
        this.totalRows = this.items.length
        this.show = false
      })
    },
    crearCliente () {
      console.log('crear cliente')
      this.infoModal.title = 'Crear Cliente'
      this.infoModal.content = ''
      this.$root.$emit('bv::show::modal', this.infoModal.id)
    },
    borrarCliente (item) {
      const itemJSON = JSON.parse(item)
      console.log(itemJSON.nro_cliente_sap)
      this.deleteCliente(itemJSON.nro_cliente_sap).then((res) => {
        console.log('deleteCliente ' + res)
        this.hideDeleteModal()
        const page = {
          page: 0,
          size: this.perPage
        }
        this.getAllClientesPage(page).then((res) => {
          this.items = this.clientePage.content
          this.totalRows = this.items.length
        })
        // this.items = this.clientes
        // this.totalRows = this.items.length
      })
    },
    makeToast (toast) {
      this.$bvToast.toast(toast.body, {
        title: toast.title,
        variant: toast.variant,
        autoHideDelay: 5000
      })
    },
    exportarExcel () {
      console.log('exportar excel')
      // XLSX.writeFile(wb, 'cumplimiento.xlsx')
      // const itemsAux = []
      // let items = {}
      // for (const item in this.items) {
      //   items = {}
      //   for (const [key, value] of Object.entries(this.items[item])) {
      //     if (!key.includes('_cellVariants')) {
      //       items[key] = value
      //     }
      //   }
      //   itemsAux.push(items)
      // }
      const ws = XLSX.utils.json_to_sheet(this.items)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'maestro clientes')
      XLSX.writeFile(wb, 'clientes.xlsx')
      // console.log(itemsAux)
      // console.log(semanas)
      // console.log(this.items)
    },
    exportarExcelCompleto () {
      this.show = true
      console.log('exportar excel completp')
      this.getAllClientes().then((res) => {
        console.log('getAllClientes ' + res)
        const clientesConTelefonosString = this.clientes.map(function (cliente) {
          var n = Object.assign({}, cliente)
          n.telefono = cliente.telefono.join(', ')
          return n
        })
        const ws = XLSX.utils.json_to_sheet(clientesConTelefonosString)
        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, 'maestro clientes')
        XLSX.writeFile(wb, 'clientes.xlsx')
        this.show = false
      })
    }
  },
  computed: {
    ...mapState('Clientes', ['clientes', 'clientePage']),
    sortOptions () {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key }
        })
    }
  }
}

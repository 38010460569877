// import { mapActions, mapState } from 'vuex'
// import * as XLSX from 'xlsx/xlsx.mjs'
// import store from '@/store'
// import axios from 'axios'
// import router from '@/router/'
// import PanelEntregaDiaria from '@/components/Layout/Panel_entrega_diaria/PanelEntregaDiariaView.vue'
// import BarChart from '@/components/Layout/Menu/Graficos/BarChart/BarChart.vue'
// import store from '@/store'
import Comentarios from '@/components/Layout/Comentarios/ComentariosView.vue'
import CrearComentariosView from '@/components/Layout/Comentarios/Crear_comentarios/CrearComentariosView.vue'
import FiltroGenericoTablasView from '@/components/Layout/Filtros/Filtro_generico_tablas/FiltroGenericoTablasView.vue'

export default {
  name: 'ComentariosTotalesView',
  components: {
    Comentarios,
    CrearComentariosView,
    FiltroGenericoTablasView
    // PanelEntregaDiaria
  },
  props: {
    filtroEntregaDiaria: null
  },
  data: function () {
    return {
      comentario: [],
      tipo: '',
      crearComentario: {},
      crearComentarioModal: {
        id: 'crearComentarioo-modal',
        title: '',
        content: ''
      },
      updateComentarios: {}
    }
  },
  watch: {
    filtroEntregaDiaria: function (val) {
      // console.log('entra en observador filtroEntregaDiaria')
      console.log(this.filtroEntregaDiaria)
    },
    updateComentarios: function (val) {
      // console.log('actualiza comentario')
      // console.log(this.updateComentarios[0])
      this.hideComentarioModal()
      this.comentario = this.updateComentarios[0]
    }
  },
  mounted () {
    // console.log(this.filtroEntregaDiaria)
    // console.log(this.filtroEntregaDiaria.nro_transporte)
    if (this.filtroEntregaDiaria !== null) {
      this.comentario = this.filtroEntregaDiaria
      this.tipo = 'byTransporte'
    } else {
      this.tipo = 'byAll'
    }
  },
  methods: {
    // ...mapActions('AdministradorFlota', [
    //   'getTransportes', 'getConductores', 'getAyudantes', 'getCamiones'
    // ])
    crearComentarioView () {
      this.crearComentarioModal.title = 'Crear Comentario General'
      // this.crearComentarioModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.crearComentarioModal.id)
    },
    resetComentarioModal () {
      this.crearComentarioModal.title = ''
      this.crearComentarioModal.content = ''
    },
    hideComentarioModal () {
      this.$refs[this.crearComentarioModal.id].hide()
    },
    volverPanelEntrega () {
      // console.log('volverPanelEntrega')
      const dateComentario = new Date()
      this.$emit('volverPanelEntrega', dateComentario)
    }
  },
  computed: {
    // ...mapState('AdministradorFlota', ['transportes'])
  }
}

import { mapActions } from 'vuex'

export default {
  name: 'CrearEntregaDiariaView',
  components: {
  },
  props: {
  },
  data: function () {
    return {
      nro_transporte: null,
      nro_cliente: null,
      nombre_cliente: null,
      telefono: null,
      direccion: null,
      fecha_ruta: null,
      nro_camion: null,
      nombre_conductor: null,
      cd: null,
      uzt: null,
      vuelta: null,
      total_uc: null,
      geolocalizacion: null,
      motivo_no_entrega_inicial: null,
      motivo_no_entrega_final: null,
      estado: null,
      documentos: [],
      nro_documento: null,
      nro_pedido: null,
      detalle: null,
      uc_documento: null,
      showDocumentos: true,
      optionsMotivosNoEntrega: [
        { value: null, text: '--SELECCIONAR MOTIVO NO ENTREGA--' },
        { value: 'Motivos MDA', text: 'Motivos MDA' },
        { value: 'CERRADO (06)', text: 'CERRADO (06)' },
        { value: 'CLIENTE ANULA PEDIDO (08)', text: 'CLIENTE ANULA PEDIDO (08)' },
        { value: 'DIFERENCIA EN CONDICIÓN DE PAGO (11)', text: 'DIFERENCIA EN CONDICIÓN DE PAGO (11)' },
        { value: 'DIFICULTAD EN RUTA (ASALTO/INACCESIBLE/CAMIÓN EN PANNE) (13)', text: 'DIFICULTAD EN RUTA (ASALTO/INACCESIBLE/CAMIÓN EN PANNE) (13)' },
        { value: 'DUPLICADO/MAL DIGITADO (14)', text: 'DUPLICADO/MAL DIGITADO (14)' },
        { value: 'ENVASE (25)', text: 'ENVASE (25)' },
        { value: 'EXCESO DE CLIENTES (24)', text: 'EXCESO DE CLIENTES (24)' },
        { value: 'FALTA DE PRODUCTO EN (26)', text: 'FALTA DE PRODUCTO EN (26)' },
        { value: 'FUERA DE RUTA (23)', text: 'FUERA DE RUTA (23)' },
        { value: 'HORARIO INADECUADO (20)', text: 'HORARIO INADECUADO (20)' },
        { value: 'PROBLEMA DE FECHA (12)', text: 'PROBLEMA DE FECHA (12)' },
        { value: 'PRODUCTO DETERIORADO (04)', text: 'PRODUCTO DETERIORADO (04)' },
        { value: 'SIN DINERO (05)', text: 'SIN DINERO (05)' },
        { value: 'SIN ORDEN DE COMPRA (18)', text: 'SIN ORDEN DE COMPRA (18)' },
        { value: 'SOBRE STOCK (10)', text: 'SOBRE STOCK (10)' },
        { value: 'CLIENTE NO VISITADO', text: 'CLIENTE NO VISITADO' }
      ],
      spinner: false
    }
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    ...mapActions('EntregaDiaria', [
      'getEntregaDiaria', 'actualizaEntregaDiariaMotivoNoEntrega', 'setEntregaDiaria', 'updateEntregaDiariaAsync', 'setEntregaDiariaAsync', 'updatePlanificacionAsync'
    ]),
    AgregarDocumento () {
      if (this.nro_documento === null) {
        this.makeToast({
          body: 'Se debe ingresar Nro de Documento',
          title: 'Parametros sin completar',
          variant: 'danger'
        })
        return
      }
      const document = {
        nro_documento: parseInt(this.nro_documento),
        nro_pedido: parseInt(this.nro_pedido),
        detalle: this.detalle,
        cantidad_uc: parseFloat(this.uc_documento),
        fecha: this.fecha_ruta + ' 00:00:00',
        nro_cliente: parseInt(this.nro_cliente),
        nombre_cliente: this.nombre_cliente,
        nro_transporte: parseInt(this.nro_transporte),
        nombre_conductor: this.nombre_conductor,
        vuelta: parseInt(this.vuelta)
      }
      this.documentos.push(document)
      this.nro_documento = null
      this.nro_pedido = null
      this.detalle = null
      this.uc_documento = null
      this.makeToast({
        body: 'Documento ' + document.nro_documento + ' ingresado con exito!',
        title: 'Operacion exitosa',
        variant: 'success'
      })
    },
    EliminarDocumento (index) {
      // console.log(index)
      this.documentos.splice(index, 1)
    },
    CrearEntregaDiaria () {
      // const toast = {
      //   body: 'body',
      //   title: 'title',
      //   variant: 'success'
      // }
      if (this.nro_transporte === null || this.nro_cliente === null || this.nombre_cliente === null || this.fecha_ruta === null === null || this.vuelta === null || this.cd === null) {
        this.makeToast({
          body: 'Falta informacion en "Datos Entrega Diaria" para poder crear entrega',
          title: 'Parametros sin completar',
          variant: 'danger'
        })
        return
      }
      if (this.nro_transporte === '' || this.nro_cliente === '' || this.nombre_cliente === '' || this.fecha_ruta === '' || this.vuelta === '' || this.cd === '') {
        this.makeToast({
          body: 'Falta informacion en "Datos Entrega Diaria" para poder crear entrega',
          title: 'Parametros sin completar',
          variant: 'danger'
        })
        return
      }
      if (this.nro_transporte === '0' || this.nro_cliente === '0' || this.vuelta === '0') {
        this.makeToast({
          body: 'Falta informacion en "Datos Entrega Diaria" para poder crear entrega',
          title: 'Parametros sin completar',
          variant: 'danger'
        })
        return
      }
      // console.log(this.documentos.length)
      // console.log(this.documentos)
      // if (this.documentos.length === 0) {
      //   this.makeToast({
      //     body: 'Falta informacion en "Datos Documentos" para poder crear entrega',
      //     title: 'No se asigno ningun documento',
      //     variant: 'danger'
      //   })
      //   return
      // }
      const entregaDiaria = {
        nro_transporte: parseInt(this.nro_transporte),
        nro_cliente: parseInt(this.nro_cliente),
        nombre_cliente: this.nro_cliente,
        telefono: this.telefono,
        direccion: this.direccion,
        fecha_ruta: this.fecha_ruta + ' 00:00:00',
        nro_camion: parseInt(this.nro_camion),
        nombre_conductor: this.nombre_conductor,
        cd: this.cd,
        uzt: this.uzt,
        vuelta: parseInt(this.vuelta),
        total_uc: parseFloat(this.total_uc),
        geolocalizacion: this.geolocalizacion,
        motivo_no_entrega_inicial: this.motivo_no_entrega_inicial,
        motivo_no_entrega_final: this.motivo_no_entrega_final,
        estado: this.estado,
        documentos: this.documentos
      }
      console.log(entregaDiaria)
      // const toast = {
      //   body: 'body',
      //   title: 'title',
      //   variant: 'success'
      // }
      // this.makeToast(toast)
      const entregaDiariaList = []
      entregaDiariaList.push(entregaDiaria)
      this.spinner = true
      this.setEntregaDiariaAsync(entregaDiariaList).then((res) => {
        this.spinner = false
        console.log('setEntregaDiariaAsync')
        const toast = {}
        toast.body = 'Se ha cargado el archivo con exito'
        toast.title = 'Carga Exitosa'
        toast.variant = 'success'
        this.makeToast(toast)
        // this.fileRouteView = []
        // this.$emit('updateClientes', this.clientes)
        this.$emit('updateCrearEntregaDiaria', entregaDiaria)
      })
    },
    showDocumentosEnable (flag) {
      this.showDocumentos = flag
    },
    makeToast (toast) {
      this.$bvToast.toast(toast.body, {
        title: toast.title,
        variant: toast.variant,
        autoHideDelay: 2000
      })
    }
  },
  computed: {
    estadoNroTransporte () {
      if (this.nro_transporte !== null && this.nro_transporte.length > 0) {
        return true
      } else {
        return false
      }
    },
    estadoNroCliente () {
      if (this.nro_cliente !== null && this.nro_cliente.length > 0) {
        return true
      } else {
        return false
      }
    },
    estadoNombreCliente () {
      if (this.nombre_cliente !== null && this.nombre_cliente.length > 0) {
        return true
      } else {
        return false
      }
    },
    estadoFechaRuta () {
      if (this.fecha_ruta !== null && this.fecha_ruta.length > 0) {
        return true
      } else {
        return false
      }
    },
    estadoCd () {
      if (this.cd !== null && this.cd.length > 0) {
        return true
      } else {
        return false
      }
    },
    estadoNroVuelta () {
      if (this.vuelta !== null && this.vuelta.length > 0) {
        return true
      } else {
        return false
      }
    },
    estadoNroDocumento () {
      if (this.nro_documento !== null && this.nro_documento.length > 0) {
        return true
      } else {
        return false
      }
    },
    estadoNroPedido () {
      if (this.nro_pedido !== null && this.nro_pedido.length > 0) {
        return true
      } else {
        return false
      }
    }
  }
}

import store from '@/store'
import axios from 'axios'
import router from '@/router/'

export default {
  namespaced: true,
  state: {
    reporteGestion: []
  },
  mutations: {
    set_accion_reporteGestion (state, reporteGestion) {
      state.reporteGestion = reporteGestion
    }
  },
  actions: {
    async getReporteGestion ({ commit, dispatch, state }, filtro) {
      try {
        const response = await axios({
          method: 'post',
          // url: `https://n1ffdxatvc.execute-api.us-west-1.amazonaws.com/prod/cliente/${store.state.cliente}/equipo?marca=${store.state.marca}`,
          // url: `http://${store.state.host}/api/vps/getTransportes`,
          url: `${store.state.host}/api/bitacora/reportes/reporteGestiones`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          },
          data: filtro
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api getReporteGestion')
          console.log(response.data)
          commit('set_accion_reporteGestion', response.data)
          return true
        } else {
          console.log('por else getReporteGestion')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getReporteGestion')
        router.push({ path: '/' })
        return false
      }
    },
    async setPlanificaciones ({ commit, dispatch, state }, file) {
      try {
        const response = await axios({
          method: 'post',
          url: `${store.state.host}/api/bitacora/reportes/excelPlanificacionMultipart`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          },
          data: file
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api setPlanificaciones')
          console.log(response.data)
          // commit('set_accion_dotaciones', response)
          return true
        } else {
          console.log('por else setPlanificaciones')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch setPlanificaciones')
        router.push({ path: '/' })
        return false
      }
    }
  },
  getters: {
  }
}

// import { mapActions, mapState } from 'vuex'
// import * as XLSX from 'xlsx/xlsx.mjs'
// import store from '@/store'
// import axios from 'axios'
// import router from '@/router/'
// import PanelEntregaDiaria from '@/components/Layout/Panel_entrega_diaria/PanelEntregaDiariaView.vue'
// import BarChart from '@/components/Layout/Menu/Graficos/BarChart/BarChart.vue'
// import store from '@/store'

export default {
  name: 'FiltroGenericoTablasView',
  components: {
  },
  props: {
    clientes: null,
    transportes: null,
    estados: null,
    camiones: null,
    cd: null,
    fechaRutaEnable: null,
    usuarioFiltroEnable: null,
    tipoGestionFiltroEnable: null
  },
  data: function () {
    return {
      options: [],
      value: [],
      optionsClientes: ['Andina', 'Sopraval', 'Soprole'],
      // valueClientes: [],
      valueClientes: null,
      optionsTransportes: [],
      // valueTransportes: [],
      valueTransportes: null,
      optionsEstados: ['Realizado', 'No Realizado'],
      // valueEstados: [],
      valueEstados: null,
      optionsCamiones: ['cam 1', 'cam 2', 'cam 3'],
      valueCamiones: [],
      optionsCD: ['Puente Alto CD', 'Quinta Normal CD'],
      valueCD: null,
      fechaRutaDesde: '',
      fechaRutaHasta: '',
      fechaComentario: '',
      selectNroCliete: 0,
      selectNroTransporte: 0,
      selectNroCamion: null,
      selectCD: '',
      // SelectEstado: '',
      SelectEstado: null,
      optionsEstado: [
        { value: null, text: 'Seleccionar estado' },
        { value: 'Entregado', text: 'Entregado' },
        { value: 'No Entregado', text: 'No Entregado' },
        { value: 'Entrega Parcial', text: 'Entrega Parcial' },
        // { value: '', text: 'Sin gestionar' },
        { value: 'En Gestion', text: 'En Gestion' }
        // { value: 'concluido', text: 'Concluido' }
      ],
      optionsCd: [
        { value: null, text: 'Seleccionar CD' },
        { value: 'CD Antofagasta', text: 'CD Antofagasta' },
        { value: 'CD Calama', text: 'CD Calama' },
        { value: 'CD Valdovinos', text: 'CD Carlos Valdovinos' },
        { value: 'CD Colina', text: 'CD Colina' },
        { value: 'CD Copiapo', text: 'CD Copiapo' },
        { value: 'CD Coquimbo', text: 'CD Coquimbo' },
        { value: 'CD Coyhaique', text: 'CD Coyhaique' },
        { value: 'CD Illapel', text: 'CD Illapel' },
        { value: 'CD Maipu', text: 'CD Maipu' },
        { value: 'CD Ovalle', text: 'CD Ovalle' },
        { value: 'CD Puente Alto', text: 'CD Puente Alto' },
        { value: 'CD Puerto Natales', text: 'CD Puerto Natales' },
        { value: 'CD Punta Arenas', text: 'CD Punta Arenas' },
        { value: 'CD Rancagua', text: 'CD Rancagua' },
        { value: 'CD Renca', text: 'CD Renca' },
        { value: 'CD San Antonio', text: 'CD San Antonio' },
        { value: 'CD Tocopilla', text: 'CD Tocopilla' }
      ],
      selectedGestion: null,
      optionsGestion: [
        { value: null, text: 'Seleccione Tipo Gestion' },
        { value: 'Incidencia', text: 'Incidencia' },
        { value: 'No Incidencia', text: 'No Incidencia' },
        { value: 'Gestion tiempo supermercado', text: 'Gestion tiempo supermercado' }
      ],
      usuarioFiltro: false
    }
  },
  watch: {
    clientes: function (val) {
      this.optionsClientes = []
      this.optionsClientes = this.clientes
    },
    transportes: function (val) {
      this.optionsTransportes = []
      this.optionsTransportes = this.transportes
    },
    estados: function (val) {
      this.optionsEstados = []
      this.optionsEstados = this.estados
    },
    // camiones: function (val) {
    //   this.optionsCamiones = []
    //   this.optionsCamiones = this.camiones
    // },
    cd: function (val) {
      this.optionsCD = []
      this.optionsCD = this.cd
    }
  },
  mounted () {
    if (this.transportes !== null) {
      this.optionsTransportes = this.transportes
    }
    if (this.clientes !== null) {
      this.optionsClientes = this.clientes
    }
    if (this.estados !== null) {
      this.optionsEstados = this.estados
    }
    if (this.camiones !== null) {
      this.optionsCamiones = this.camiones
    }
    if (this.cd !== null) {
      this.optionsCD = this.cd
    }
  },
  methods: {
    // ...mapActions('AdministradorFlota', [
    //   'getTransportes', 'getConductores', 'getAyudantes', 'getCamiones'
    // ])
    filtrar () {
      const filtro = {}
      if (this.valueTransportes === '') this.valueTransportes = null
      if (this.valueClientes === '') this.valueClientes = null
      if (this.valueEstados === '') this.valueEstados = null
      if (this.selectNroCamion === '') this.selectNroCamion = null
      if (this.valueCD === '') this.valueCD = null
      if (this.selectedGestion === '') this.selectedGestion = null
      filtro.tipo_gestion = this.selectedGestion
      filtro.transportes = this.valueTransportes
      filtro.clientes = this.valueClientes
      filtro.estados = this.valueEstados
      filtro.camiones = this.selectNroCamion
      filtro.cd = this.valueCD
      filtro.usuarioFiltro = this.usuarioFiltro
      // console.log('fecha desde null')
      filtro.periodos = {}
      if (this.fechaRutaDesde !== null && this.fechaRutaDesde !== undefined && this.fechaRutaDesde !== '') {
        filtro.periodos.desde = this.fechaRutaDesde
      }
      if (this.fechaRutaHasta !== null && this.fechaRutaHasta !== undefined && this.fechaRutaHasta !== '') {
        filtro.periodos.hasta = this.fechaRutaHasta
      }
      // console.log(filtro)
      this.$emit('filtro', filtro)
    }
  },
  computed: {
    availableOptions () {
      return this.options.filter(opt => this.value.indexOf(opt) === -1)
    },
    availableOptionsClientes () {
      return this.optionsClientes.filter(opt => this.value.indexOf(opt) === -1)
    },
    availableOptionsTransportes () {
      return this.optionsTransportes.filter(opt => this.value.indexOf(opt) === -1)
    },
    availableOptionsEstados () {
      return this.optionsEstados.filter(opt => this.value.indexOf(opt) === -1)
    },
    availableOptionsCamiones () {
      return this.optionsCamiones.filter(opt => this.value.indexOf(opt) === -1)
    },
    availableOptionsCD () {
      return this.optionsCD.filter(opt => this.value.indexOf(opt) === -1)
    },
    fechaRutaDesdeSelected () {
      return this.fechaRutaDesde.length > 0
    },
    fechaRutaHastaSelected () {
      return this.fechaRutaHasta.length > 0
    }
  }
}

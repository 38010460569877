import { mapActions, mapState } from 'vuex'
// import * as XLSX from 'xlsx/xlsx.mjs'
// import store from '@/store'
// import axios from 'axios'
// import router from '@/router/'
import FiltroGenericoTablasView from '@/components/Layout/Filtros/Filtro_generico_tablas/FiltroGenericoTablasView.vue'
import DocumentosPedidoView from '@/components/Layout/Documentos_pedido/DocumentosPedidoView.vue'
import CrearComentariosView from '@/components/Layout/Comentarios/Crear_comentarios/CrearComentariosView.vue'
import CargarEntregaDiariaView from '@/components/Layout/Panel_entrega_diaria/Cargar_entrega_diaria/CargarEntregaDiariaView.vue'
import CrearEntregaDiariaView from '@/components/Layout/Panel_entrega_diaria/Crear_entrega_diaria/CrearEntregaDiariaView.vue'
import ContadorGestionView from '@/components/Layout/Panel_entrega_diaria/Contador_gestion/ContadorGestionView.vue'
// import BarChart from '@/components/Layout/Menu/Graficos/BarChart/BarChart.vue'
// import store from '@/store'

export default {
  name: 'EntregaDiaria',
  components: {
    DocumentosPedidoView,
    CrearComentariosView,
    CargarEntregaDiariaView,
    FiltroGenericoTablasView,
    CrearEntregaDiariaView,
    ContadorGestionView
  },
  props: {
  },
  data: function () {
    return {
      itemsAux: [],
      items: [],
      fields: [
        { key: 'nro_transporte', label: 'Nro Transporte', sortable: true, sortDirection: 'desc', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'nro_cliente', label: 'Nro Cliente', sortable: true, class: 'text-center', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'estado', label: 'Estado', sortable: true, class: 'text-center', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'accions', label: 'Accion', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'motivo_no_entrega_inicial', label: 'Motivo No Entrega Inicial', sortable: true, class: 'text-center', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'motivo_no_entrega_final', label: 'Motivo No Entrega Final', sortable: true, class: 'text-center', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        // { key: 'motivo_no_entrega', label: 'Motivo No Entrega', sortable: true, class: 'text-center', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'total_uc', label: 'UC Programadas', sortable: true, class: 'text-center', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'botellas', label: 'Botellas', sortable: true, class: 'text-center', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'cajas', label: 'Cajas', sortable: true, class: 'text-center', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'pac', label: 'Pac', sortable: true, class: 'text-center', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'detalle', label: 'Detalle', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'comentarios', label: 'Comentarios', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'fecha_ruta', label: 'Fecha Ruta', sortable: true, class: 'text-center', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'cd', label: 'CD', sortable: true, class: 'text-center', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'nombre_cliente', label: 'Nombre Cliente', sortable: true, class: 'text-center', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'telefono', label: 'Telefono', sortable: true, class: 'text-center', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'direccion', label: 'Direccion', sortable: true, class: 'text-center', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'uzt', label: 'UZT', sortable: true, class: 'text-center', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'nro_camion', label: 'Nro Camion', sortable: true, class: 'text-center', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'vuelta', label: 'Vuelta', sortable: true, class: 'text-center', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'nombre_conductor', label: 'Nombre Conductor', sortable: true, class: 'text-center', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'geolocalizacion', label: 'Geolocalizacion', sortable: true, class: 'text-center', thStyle: { background: '#FF9B00', color: '#ffffff' } },
        { key: 'duracion_gestion', label: 'Duracion Gestion', sortable: true, class: 'text-center', thStyle: { background: '#FF9B00', color: '#ffffff' } }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, { value: 100, text: 'Show a lot' }],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal-documento-pedido',
        title: '',
        content: ''
      },
      crearComentarioModal: {
        id: 'crearComentario-modal',
        title: '',
        content: ''
      },
      clienteModal: {
        id: 'cliente-modal',
        title: '',
        content: ''
      },
      checkModal: {
        id: 'check-modal',
        title: '',
        content: ''
      },
      crearEntregaDiariaModal: {
        id: 'crearEntregaDiaria-modal',
        title: '',
        content: ''
      },
      updateEditarNroCamion: {
        id: 'updateEditarNroCamionModal-modal',
        title: '',
        content: ''
      },
      activeColor: 'arial',
      fontSize: 13,
      backgroundColor: '#91c27d',
      clientes: [],
      transportes: [],
      estados: [],
      camiones: [],
      cd: [],
      filtro: null,
      datosClienteObject: '',
      motivoNoEntregaInicial: '',
      updateComentarios: '',
      updateCrearEntregaDiaria: '',
      motivoNoEntregaInicialObject: {},
      spinner: false,
      options: [],
      page: 1,
      totalEntregaDiariaPage: 0,
      totalPaginasEntregaDiariaPage: 0,
      periodos: {},
      selectedEstadoCierre: null,
      optionsEstadoCierre: [
        { value: null, text: 'Seleccionar Estado de Cierre' },
        { value: 'Entregado', text: 'Entregado' },
        { value: 'No Entregado', text: 'No Entregado' },
        { value: 'Entrega Parcial', text: 'Entrega Parcial' }
      ],
      showMenu: false,
      menuTop: 0,
      menuLeft: 0,
      activeIndex: -1,
      newNroCamion: null
    }
  },
  watch: {
    filtro: function (val) {
      this.filtrarAccion(0)
    },
    updateCrearEntregaDiaria: function (val) {
      // console.log('updateComentarios')
      this.hidecrearEntregaDiariaModal()
      this.entregaDiariaInicial()
    },
    updateComentarios: function (val) {
      // console.log('updateComentarios')
      this.hidecrearComentarioModal()
      this.entregaDiariaInicial()
    },
    updateComentariosDocumentos: function (val) {
      // console.log('updateComentariosDocumentos')
      this.entregaDiariaInicial()
    },
    perPage: function (val) {
      const data = {
        page: 0,
        size: this.perPage,
        periodos: this.periodos
      }
      this.getEntregaDiariaPage(data).then((res) => {
        this.totalEntregaDiariaPage = this.entregaDiariaPage.totalElements
        this.totalPaginasEntregaDiariaPage = this.entregaDiariaPage.totalPages
        this.items = []
        this.items = this.entregaDiariaPage.content
        for (const item in this.items) {
          this.items[item].fecha_ruta = this.items[item].fecha_ruta.replace('T', ' ').substring(0, 18)
        }
        this.listasFiltros()
        this.itemsAux = this.items
        this.totalRows = this.items.length
        this.creaListaPrimerMotivo()
        this.checkComentarios()
      })
    }
  },
  mounted () {
    this.options = this.motivosNoEntrega
    if (this.entregaDiaria !== [] && this.entregaDiaria.length > 0) {
      this.spinner = true
      this.items = []
      this.items = this.entregaDiaria.content
      for (const item in this.items) {
        this.items[item].fecha_ruta = this.items[item].fecha_ruta.replace('T', ' ').substring(0, 18)
      }
      this.listasFiltros()
      this.itemsAux = this.items
      this.totalRows = this.items.length
      this.creaListaPrimerMotivo()
      this.spinner = false
    } else {
      this.entregaDiariaInicial()
    }
  },
  methods: {
    ...mapActions('EntregaDiaria', [
      'getEntregaDiaria', 'actualizaEntregaDiariaMotivoNoEntrega', 'updateEstado', 'getEntregaDiariaPage', 'getFilterPage', 'updateNroCamion'
    ]),
    ...mapActions('Clientes', [
      'getClienteById'
    ]),
    ...mapActions('Comentarios', [
      'getComentariosByNroTransporteAndNroCliente'
    ]),
    ...mapActions(['setPeriodoEntregaDiaria']),
    entregaDiariaInicial () {
      const date = new Date()
      const fechaActualDesde = `${
        date.getFullYear().toString().padStart(4, '0')}-${
          (date.getMonth() + 1).toString().padStart(2, '0')}-${
            date.getDate().toString().padStart(2, '0')} ${
              '00'}:${
                '00'}:${
                  '00'}`
      const fechaActualHasta = `${
        date.getFullYear().toString().padStart(4, '0')}-${
          (date.getMonth() + 1).toString().padStart(2, '0')}-${
            date.getDate().toString().padStart(2, '0')} ${
              date.getHours().toString().padStart(2, '0')}:${
                date.getMinutes().toString().padStart(2, '0')}:${
                  date.getSeconds().toString().padStart(2, '0')}`
      // console.log(fechaActualDesde)
      // console.log(fechaActualHasta)
      this.periodos = {
        desde: fechaActualDesde,
        hasta: fechaActualHasta
      }
      let transporte = null
      let cliente = null
      let estado = null
      let camion = null
      let cd = null
      // console.log(this.filtro)
      if (this.filtro !== null) {
        transporte = parseInt(this.filtro.transportes)
        cliente = parseInt(this.filtro.clientes)
        estado = this.filtro.estados
        cd = this.filtro.cd
        camion = parseInt(this.filtro.camiones)
        if (isNaN(transporte)) {
          transporte = null
        }
        if (isNaN(cliente)) {
          cliente = null
        }
        if (isNaN(camion)) {
          camion = null
        }
        if (!isNaN(cd)) {
          cd = null
        }
        this.periodos.desde = this.filtro.periodos.desde + ' 00:00:00'
        this.periodos.hasta = this.filtro.periodos.hasta + ' 23:59:59'
      } else if (this.periodoEntregaDiaria !== null) {
        // console.log(this.periodoEntregaDiaria.length)
        this.periodos.desde = this.periodoEntregaDiaria.periodos.desde + ' 00:00:00'
        this.periodos.hasta = this.periodoEntregaDiaria.periodos.hasta + ' 23:59:59'
      }
      const data = {
        page: this.page - 1,
        size: this.perPage,
        periodos: this.periodos,
        filter: {
          periodo: this.periodos,
          nro_transporte: transporte,
          nro_cliente: cliente,
          estado: estado,
          nro_camion: camion,
          cd: cd
        }
      }
      this.spinner = true
      this.getFilterPage(data).then((res) => {
        this.totalEntregaDiariaPage = this.entregaDiariaPage.totalElements
        this.totalPaginasEntregaDiariaPage = this.entregaDiariaPage.totalPages
        this.items = []
        this.items = this.entregaDiariaPage.content
        for (const item in this.items) {
          this.items[item].fecha_ruta = this.items[item].fecha_ruta.replace('T', ' ').substring(0, 18)
        }
        this.listasFiltros()
        this.itemsAux = this.items
        this.totalRows = this.items.length
        this.creaListaPrimerMotivo()
        this.checkComentarios()
        this.spinner = false
      })
    },
    handlePageChange (value) {
      this.page = value
      let page = {}
      let transporte = null
      let cliente = null
      let estado = null
      let cd = null
      let camion = null
      let tipoGestion = null
      if (this.filtro !== null) {
        transporte = parseInt(this.filtro.transportes)
        cliente = parseInt(this.filtro.clientes)
        estado = this.filtro.estados
        cd = this.filtro.cd
        camion = parseInt(this.filtro.camiones)
        tipoGestion = this.filtro.tipo_gestion
        if (isNaN(transporte)) {
          transporte = null
        }
        if (isNaN(cliente)) {
          cliente = null
        }
        if (isNaN(camion)) {
          camion = null
        }
        let analista = ''
        if (this.filtro.usuarioFiltro) {
          analista = this.user
        } else {
          analista = null
        }
        page = {
          page: (this.page - 1),
          size: this.perPage,
          filter: {
            tipo_gestion: tipoGestion,
            periodo: this.periodos,
            nro_transporte: transporte,
            nro_cliente: cliente,
            estado: estado,
            analista: analista,
            cd: cd,
            nro_camion: camion
          }
        }
      } else {
        page = {
          page: (this.page - 1),
          size: this.perPage,
          filter: {
            periodo: this.periodos
          }
        }
      }
      this.spinner = true
      this.getFilterPage(page).then((res) => {
        // console.log(this.entregaDiariaPage)
        this.totalEntregaDiariaPage = this.entregaDiariaPage.totalElements
        this.totalPaginasEntregaDiariaPage = this.entregaDiariaPage.totalPages
        this.items = []
        this.items = this.entregaDiariaPage.content
        for (const item in this.items) {
          this.items[item].fecha_ruta = this.items[item].fecha_ruta.replace('T', ' ').substring(0, 18)
        }
        this.listasFiltros()
        this.itemsAux = this.items
        this.totalRows = this.items.length
        this.creaListaPrimerMotivo()
        this.checkComentarios()
        this.spinner = false
      })
    },
    info (item, index, button) {
      this.infoModal.title = 'Documentos Transporte ' + item.nro_transporte
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal () {
      this.infoModal.title = ''
      this.infoModal.content = ''
      this.$refs[this.infoModal.id].hide()
    },
    onFiltered (filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    crearComentario (item, index, button) {
      this.crearComentarioModal.title = 'Crear Comentario'
      this.crearComentarioModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.crearComentarioModal.id, button)
    },
    resetComentarioModal () {
      this.crearComentarioModal.title = ''
      this.crearComentarioModal.content = ''
    },
    clienteOpenModal (item, index, button) {
      this.datosCliete(item)
      this.clienteModal.title = 'Cliente'
      this.clienteModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.clienteModal.id, button)
    },
    crearEntregaDiariaOpenModal (item, index, button) {
      this.crearEntregaDiariaModal.title = 'Crear Entrega Diaria'
      this.crearEntregaDiariaModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.crearEntregaDiariaModal.id, button)
    },
    hidecrearEntregaDiariaModal () {
      this.$refs[this.crearEntregaDiariaModal.id].hide()
    },
    updateEditarNroCamionOpenModal (item, index, button) {
      this.updateEditarNroCamion.title = 'Editar Nro Camion'
      this.updateEditarNroCamion.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.updateEditarNroCamion.id, button)
    },
    hideUpdateEditarNroCamion () {
      this.$refs[this.updateEditarNroCamion.id].hide()
    },
    resetClienteModal () {
      this.clienteModal.title = ''
      this.clienteModal.content = ''
    },
    hidecrearComentarioModal () {
      this.$refs[this.crearComentarioModal.id].hide()
    },
    CheckModal (item, index, button, estado) {
      console.log(item)
      if (estado === 'En Gestion') {
        this.checkModal.title = 'Abrir gestion'
      } else {
        this.checkModal.title = 'Cerrar gestion'
      }
      const newItem = {
        estado: estado,
        id_transporte: item.id_transporte,
        nro_transporte: item.nro_transporte,
        nro_cliente: item.nro_cliente
      }
      // this.checkModal.content = JSON.stringify(newItem, null, 2)
      this.checkModal.content = newItem
      this.$root.$emit('bv::show::modal', this.checkModal.id, button)
    },
    resetCheckModal () {
      this.checkModal.title = ''
      this.checkModal.content = ''
    },
    hideCheckModal () {
      this.$refs[this.checkModal.id].hide()
    },
    verComentarios (item) {
      this.$emit('verComentarios', {})
      this.$emit('verComentarios', item)
    },
    listasFiltros () {
      this.transportes = []
      this.clientes = []
      this.estados = []
      this.camiones = []
      for (const item in this.items) {
        if (this.items[item].nro_transporte !== null) {
          this.transportes.push(this.items[item].nro_transporte.toString())
        }
        if (this.items[item].nro_cliente !== null) {
          this.clientes.push(this.items[item].nro_cliente.toString())
        }
        if (this.items[item].estado !== null) {
          this.estados.push(this.items[item].estado.toString())
        }
        if (this.items[item].nro_camion !== null) {
          this.camiones.push(this.items[item].nro_camion.toString())
        }
        if (this.items[item].cd !== null) {
          this.cd.push(this.items[item].cd.toString())
        }
      }
      for (let i = this.clientes.length - 1; i >= 0; i--) {
        if (this.clientes.indexOf(this.clientes[i]) !== i) this.clientes.splice(i, 1)
      }
      for (let i = this.transportes.length - 1; i >= 0; i--) {
        if (this.transportes.indexOf(this.transportes[i]) !== i) this.transportes.splice(i, 1)
      }
      for (let i = this.estados.length - 1; i >= 0; i--) {
        if (this.estados.indexOf(this.estados[i]) !== i) this.estados.splice(i, 1)
      }
      for (let i = this.camiones.length - 1; i >= 0; i--) {
        if (this.camiones.indexOf(this.camiones[i]) !== i) this.camiones.splice(i, 1)
      }
      for (let i = this.cd.length - 1; i >= 0; i--) {
        if (this.cd.indexOf(this.cd[i]) !== i) this.cd.splice(i, 1)
      }
    },
    filtrarCampos () {
      const clientesFilter = []
      const transportesFilter = []
      const estadosFilter = []
      const cdFilter = []
      const camionesFilter = []
      for (const camion in this.filtro.camiones) {
        camionesFilter.push(this.items.filter(camionfilter => camionfilter.nro_camion === parseInt(this.filtro.camiones[camion])))
      }
      for (const cliente in this.filtro.clientes) {
        clientesFilter.push(this.items.filter(clientesfilter => clientesfilter.nro_cliente === parseInt(this.filtro.clientes[cliente])))
      }
      for (const transporte in this.filtro.transportes) {
        transportesFilter.push(this.items.filter(transportefilter => transportefilter.nro_transporte === parseInt(this.filtro.transportes[transporte])))
      }
      for (const estado in this.filtro.estados) {
        estadosFilter.push(this.items.filter(estadosfilter => estadosfilter.estado === this.filtro.estados[estado]))
      }
      for (const cd in this.filtro.cd) {
        cdFilter.push(this.items.filter(cdfilter => cdfilter.cd === this.filtro.cd[cd]))
      }
      const filtrosConcat = camionesFilter.concat(clientesFilter).concat(transportesFilter).concat(estadosFilter).concat(cdFilter)
      this.items = []
      for (const filtroConcat in filtrosConcat) {
        // this.items.push(filtrosConcat[filtroConcat])
        for (const filtroConcatsub in filtrosConcat[filtroConcat]) {
          this.items.push(filtrosConcat[filtroConcat][filtroConcatsub])
        }
      }
      console.log(this.items)
      if (this.items.length === 0) {
        this.items = this.itemsAux
      }
      this.totalRows = this.items.length
    },
    datosCliete (item, index, button) {
      console.log(item.nro_cliente)
      this.getClienteById(item.nro_cliente).then((res) => {
        if (res) {
          this.datosClienteObject = this.cliente
        } else {
          const toast = {}
          toast.body = 'Error cliente no existe'
          toast.title = 'Error'
          toast.variant = 'danger'
          this.makeToast(toast)
        }
      })
    },
    actualizaMotivoNoEntregaInicial (entrega) {
      if (this.motivoNoEntregaInicialObject[entrega.id_transporte] === null || this.motivoNoEntregaInicialObject[entrega.id_transporte] === undefined || this.motivoNoEntregaInicialObject[entrega.id_transporte] === '') {
        const toast = {}
        toast.body = 'Ingrese un motivo inicial valido'
        toast.title = 'Error'
        toast.variant = 'danger'
        this.makeToast(toast)
        return false
      }
      entrega.motivo_no_entrega_inicial = this.motivoNoEntregaInicialObject[entrega.id_transporte]
      entrega.motivo_no_entrega_final = this.motivoNoEntregaInicialObject[entrega.id_transporte]
      console.log(entrega)
      this.actualizaEntregaDiariaMotivoNoEntrega(entrega).then((res) => {
        this.entregaDiariaInicial()
      })
    },
    creaListaPrimerMotivo () {
      // console.log('creaListaPrimerMotivo')
      this.motivoNoEntregaInicialObject = {}
      for (const motivo in this.items) {
        this.motivoNoEntregaInicialObject[this.items[motivo].id_transporte] = ''
      }
    },
    UpdateEstado (data, cierre) {
      console.log(cierre)
      if (cierre === null) {
        const toast = {
          body: 'Se debe seleccionar motivo cierre de gestion',
          title: 'Invalido',
          variant: 'danger'
        }
        this.makeToast(toast)
        return
      }
      if (cierre !== '' && cierre !== null) data.estado = cierre
      this.updateEstado(data).then((res) => {
        this.entregaDiariaInicial()
        this.hideCheckModal()
      })
    },
    makeToast (toast) {
      this.$bvToast.toast(toast.body, {
        title: toast.title,
        variant: toast.variant,
        autoHideDelay: 5000
      })
    },
    checkComentarios () {
      for (const entrega in this.items) {
        const data = {
          nro_transporte: this.items[entrega].nro_transporte,
          nro_cliente: this.items[entrega].nro_cliente
        }
        this.items[entrega].check_comentarios = {}
        this.getComentariosByNroTransporteAndNroCliente(data).then((res) => {
          // console.log(res.length)
          this.items[entrega].check_comentarios = res
          this.$forceUpdate()
        })
      }
    },
    actualizaDesdeDetalle () {
      this.resetInfoModal()
      // this.entregaDiariaInicial()
      this.filtrarAccion((this.page - 1))
    },
    filtrarAccion (page) {
      this.items = this.itemsAux
      console.log(this.filtro)
      // console.log(this.periodos)
      if (this.filtro !== undefined && this.filtro !== null) {
        this.setPeriodoEntregaDiaria(this.filtro)
        const periodos = {
          desde: this.filtro.periodos.desde + ' 00:00:00',
          hasta: this.filtro.periodos.hasta + ' 23:59:59'
        }
        let analista = ''
        if (this.filtro.usuarioFiltro) {
          analista = this.user
        } else {
          analista = null
        }
        const data = {
          page: page,
          size: this.perPage,
          filter: {
            tipo_gestion: this.filtro.tipo_gestion,
            periodo: periodos,
            nro_transporte: parseInt(this.filtro.transportes),
            nro_cliente: parseInt(this.filtro.clientes),
            estado: this.filtro.estados,
            cd: this.filtro.cd,
            analista: analista,
            nro_camion: parseInt(this.filtro.camiones)
          }
        }
        if (isNaN(data.filter.nro_transporte)) {
          data.filter.nro_transporte = null
        }
        if (isNaN(data.filter.nro_cliente)) {
          data.filter.nro_cliente = null
        }
        if (isNaN(data.filter.nro_camion)) {
          data.filter.nro_camion = null
        }
        this.periodos = periodos
        this.getFilterPage(data).then((res) => {
          console.log(this.entregaDiariaPage.content)
          this.totalEntregaDiariaPage = this.entregaDiariaPage.totalElements
          this.totalPaginasEntregaDiariaPage = this.entregaDiariaPage.totalPages
          this.items = []
          this.items = this.entregaDiariaPage.content
          for (const item in this.items) {
            this.items[item].fecha_ruta = this.items[item].fecha_ruta.replace('T', ' ').substring(0, 18)
          }
          this.listasFiltros()
          this.itemsAux = this.items
          this.totalRows = this.items.length
          this.creaListaPrimerMotivo()
          this.checkComentarios()
        })
      } else if (this.periodoEntregaDiaria !== {}) {
        // console.log(this.periodoEntregaDiaria)
        // console.log(this.periodos)
        const periodos = {
          desde: this.periodos.desde,
          hasta: this.periodos.hasta
        }
        let analista = ''
        if (this.filtro !== null && this.filtro.usuarioFiltro) {
          analista = this.user
        } else {
          analista = null
        }
        let nroTransporte = null
        let nroCliente = null
        let estado = null
        let cd = null
        let nroCamion = null
        let tipoGestion = null
        if (this.filtro !== null) {
          nroTransporte = parseInt(this.filtro.transportes)
          nroCliente = parseInt(this.filtro.clientes)
          nroCamion = parseInt(this.filtro.camiones)
          estado = this.filtro.estados
          cd = this.filtro.cd
          tipoGestion = this.filtro.tipo_gestion
        }
        const data = {
          page: page,
          size: this.perPage,
          filter: {
            tipo_gestion: tipoGestion,
            periodo: periodos,
            nro_transporte: nroTransporte,
            nro_cliente: nroCliente,
            estado: estado,
            cd: cd,
            nro_camion: nroCamion,
            analista: analista
          }
        }
        this.periodos = periodos
        this.getFilterPage(data).then((res) => {
          this.totalEntregaDiariaPage = this.entregaDiariaPage.totalElements
          this.totalPaginasEntregaDiariaPage = this.entregaDiariaPage.totalPages
          // console.log(this.entregaDiariaPage)
          this.items = []
          this.items = this.entregaDiariaPage.content
          for (const item in this.items) {
            this.items[item].fecha_ruta = this.items[item].fecha_ruta.replace('T', ' ').substring(0, 18)
          }
          this.listasFiltros()
          this.itemsAux = this.items
          this.totalRows = this.items.length
          this.creaListaPrimerMotivo()
          this.checkComentarios()
        })
      } else {
        this.filtrarCampos()
      }
    },
    showContextMenu (event, index) {
      event.preventDefault()
      this.showMenu = true
      this.menuTop = event.pageY - 100
      this.menuLeft = event.pageX - 120
      // console.log(this.menuTop)
      // console.log(this.menuLeft)
      this.activeIndex = index
      // Escuchar el evento de clic en cualquier lugar de la página para cerrar el menú contextual
      document.addEventListener('click', this.hideContextMenu)
    },
    editItem () {
      console.log('Editar item ' + this.activeIndex)
      console.log(this.activeIndex)
      this.updateEditarNroCamionOpenModal(this.activeIndex)
      this.showMenu = false
    },
    deleteItem () {
      console.log('Eliminar item ' + this.activeIndex)
      // this.items.splice(this.activeIndex, 1)
      this.showMenu = false
    },
    hideContextMenu () {
      // Ocultar el menú contextual
      this.showMenu = false
      // Dejar de escuchar el evento de clic en cualquier lugar de la página
      document.removeEventListener('click', this.hideContextMenu)
    },
    editarNroCamion () {
      if (this.newNroCamion === null) {
        const toast = {
          body: 'Ingresar nuevo nro camion',
          title: 'Invalido',
          variant: 'danger'
        }
        this.makeToast(toast)
        return
      }
      const data = {
        nro_camion: parseInt(this.newNroCamion),
        id_transporte: parseInt(this.activeIndex.id_transporte)
      }
      this.updateNroCamion(data).then((res) => {
        this.hideUpdateEditarNroCamion()
        this.entregaDiariaInicial()
        this.newNroCamion = null
      })
    }
  },
  computed: {
    ...mapState('EntregaDiaria', ['entregaDiaria', 'entregaDiariaPage']),
    ...mapState(['motivosNoEntrega']),
    // ...mapState('EntregaDiaria', ['motivoNoEntregaInicialObject']),
    ...mapState('Clientes', ['cliente']),
    ...mapState(['periodoEntregaDiaria', 'user']),
    sortOptions () {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key }
        })
    }
  }
}

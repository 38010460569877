// import { mapActions, mapState } from 'vuex'
import * as XLSX from 'xlsx'
// import * as XLSX from 'xlsx/xlsx.mjs'
// import store from '@/store'
// import axios from 'axios'
// import router from '@/router/'
// import PanelEntregaDiaria from '@/components/Layout/Panel_entrega_diaria/PanelEntregaDiariaView.vue'
// import BarChart from '@/components/Layout/Menu/Graficos/BarChart/BarChart.vue'
// import store from '@/store'
// import Comentarios from '@/components/Layout/Comentarios/ComentariosView.vue'
// import CrearComentariosView from '@/components/Layout/Comentarios/Crear_comentarios/CrearComentariosView.vue'

export default {
  name: 'PanelEntregaDiariaView',
  components: {
  },
  props: {
  },
  data: function () {
    return {
      file: null,
      arrayBuffer: null,
      filelist: null
    }
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    // ...mapActions('AdministradorFlota', [
    //   'getTransportes', 'getConductores', 'getAyudantes', 'getCamiones'
    // ])
    addfile (event) {
      console.log(event)
      this.file = event.target.files[0]
      const fileReader = new FileReader()
      try {
        fileReader.readAsArrayBuffer(this.file)
      } catch (exception) {
        console.log('error')
        this.filelist = []
        return ''
      }
      fileReader.onload = (e) => {
        this.arrayBuffer = fileReader.result
        const data = new Uint8Array(this.arrayBuffer)
        const arr = []
        for (var i = 0; i !== data.length; ++i) {
          arr[i] = String.fromCharCode(data[i])
        }
        const bstr = arr.join('')
        const workbook = XLSX.read(bstr, { type: 'binary' })
        console.log(workbook)
        // para leer diferentes hojas editar la posicion del array workbook.SheetNames[0]
        const firstSheetName = workbook.SheetNames[0]
        const worksheet = workbook.Sheets[firstSheetName]
        const arrayRespuesta = XLSX.utils.sheet_to_json(worksheet, { raw: true })
        console.log(arrayRespuesta)
        this.filelist = []
        console.log(this.filelist)
        // Creamos un nuevo objeto donde vamos a almacenar por ciudades.
        const nuevoObjeto = {}
        // Recorremos el arreglo para crear los array en base de cliente y transporte
        arrayRespuesta.forEach(x => {
          console.log(x)
          nuevoObjeto[x.Cliente + ' ' + x.Transporte] = {
            documentos: [],
            cd: '',
            nro_cliente: 0,
            nombre_cliente: '',
            telefono: '',
            direccion: '',
            uzt: '',
            nro_camion: 0,
            vuelta: 0,
            nombre_conductor: '',
            total_uc: 0.0
          }
        })
        // una vez creados se empienza a insertar datos
        arrayRespuesta.forEach(x => {
          nuevoObjeto[x.Cliente + ' ' + x.Transporte].documentos.push({
            fecha: x['Fecha Entrega'].replace('.', '-') + ' 00:00:00',
            nro_cliente: x.Cliente,
            nombre_cliente: x.Nombre,
            nro_transporte: x.Transporte,
            nro_documento: x.pedido,
            nombre_conductor: '',
            cantidad_uc: x.UC,
            estado: '',
            vuelta: x.Vuelta
          })
          nuevoObjeto[x.Cliente + ' ' + x.Transporte].nro_transporte = x.Transporte
          nuevoObjeto[x.Cliente + ' ' + x.Transporte].cd = x.CD
          nuevoObjeto[x.Cliente + ' ' + x.Transporte].fecha_ruta = x['Fecha Entrega'].replaceAll('.', '-') + ' 00:00:00'
          nuevoObjeto[x.Cliente + ' ' + x.Transporte].nro_cliente = x.Cliente
          nuevoObjeto[x.Cliente + ' ' + x.Transporte].nombre_cliente = x.Nombre
          nuevoObjeto[x.Cliente + ' ' + x.Transporte].telefono = ''
          nuevoObjeto[x.Cliente + ' ' + x.Transporte].direccion = x.Direccion
          nuevoObjeto[x.Cliente + ' ' + x.Transporte].uzt = x.UZT
          nuevoObjeto[x.Cliente + ' ' + x.Transporte].nro_camion = 0
          nuevoObjeto[x.Cliente + ' ' + x.Transporte].vuelta = x.Vuelta
          nuevoObjeto[x.Cliente + ' ' + x.Transporte].nombre_conductor = ''
          nuevoObjeto[x.Cliente + ' ' + x.Transporte].total_uc = x.UC
        })
        console.log(nuevoObjeto)
        // const entregasDiarias = []
        for (const [key, value] of Object.entries(nuevoObjeto)) {
          // const entregaDiaria = {}
          console.log(`${key}: ${value}`)
        }
      }
    },
    cargarExcel () {
      console.log('cargarExcel')
      const toast = {}
      toast.body = 'Se ha cargado el archivo con exito'
      toast.title = 'Carga Exitosa'
      toast.variant = 'success'
      this.makeToast(toast)
    },
    makeToast (toast) {
      this.$bvToast.toast(toast.body, {
        title: toast.title,
        variant: toast.variant,
        autoHideDelay: 2000
      })
    }
  },
  computed: {
    // ...mapState('AdministradorFlota', ['transportes'])
  }
}

import { mapActions, mapState } from 'vuex'
// import * as XLSX from 'xlsx/xlsx.mjs'
import store from '@/store'
// import axios from 'axios'
// import router from '@/router/'
// import ComentariosDetalleView from '@/components/Layout/Comentarios_detalle/ComentariosDetalleView.vue'
// import BarChart from '@/components/Layout/Menu/Graficos/BarChart/BarChart.vue'
// import store from '@/store'

export default {
  name: 'CrearComentarioView',
  components: {
  },
  props: {
    data: null
  },
  data: function () {
    return {
      dataJson: [],
      selectedClasificacionComercial: null,
      optionsClasificacionComercial: [
        { value: null, text: 'Seleccione Clasificacion Comercial' },
        { value: 'Coordinaciones de horarios', text: 'Coordinaciones de horarios' },
        { value: 'Estado de pedido', text: 'Estado de pedido' },
        { value: 'Faltas de productos', text: 'Faltas de productos' },
        { value: 'Cancelación de visita', text: 'Cancelación de visita' }
      ],
      selectedCoordinacion: null,
      optionsCoordinacion: [
        { value: null, text: 'Seleccione Tipo Coordinacion' },
        { value: 'Comercial', text: 'Comercial' },
        { value: 'Logística', text: 'Logística' },
        { value: 'Otros', text: 'Otros' }
      ],
      selectedOrigenContacto: null,
      optionsOrigenContacto: [
        { value: null, text: 'Seleccione Origen Contacto' },
        { value: 'Mesa Ayuda', text: 'Mesa Ayuda' },
        { value: 'Área Andina', text: 'Área Andina' }
      ],
      selectedGestion: null,
      optionsGestion: [
        { value: null, text: 'Seleccione Tipo Gestion' },
        { value: 'Incidencia', text: 'Incidencia' },
        { value: 'No Incidencia', text: 'No Incidencia' },
        { value: 'Gestion tiempo supermercado', text: 'Gestion tiempo supermercado' }
      ],
      selectedRespuesta: null,
      optionsRespuesta: [
        { value: null, text: 'Seleccione Respuesta' },
        { value: 'Satisfactoria', text: 'Satisfactoria' },
        { value: 'Sin Respuesta', text: 'Sin Respuesta' },
        { value: 'Pendiente', text: 'Pendiente' }
      ],
      fechaRuta: '',
      nroCliente: '',
      nroTransporte: '',
      nombreCliente: '',
      nroDocumento: '',
      fechaHoraComentario: '',
      analista: '',
      comentario: '',
      idTransporte: '',
      motivoNoEntrega: '',
      options: []
    }
  },
  watch: {
  },
  mounted () {
    this.options = this.motivosNoEntrega
    console.log(typeof this.data)
    if (typeof this.data !== 'object') {
      this.dataJson = JSON.parse(this.data)
    } else {
      this.dataJson = this.data
    }
    console.log(this.dataJson)
    this.fechaRuta = this.dataJson.fecha_ruta.replace('T', ' ').substring(0, 19)
    this.estado = this.dataJson.estado
    this.nroCliente = this.dataJson.nro_cliente
    this.nroTransporte = this.dataJson.nro_transporte
    this.nombreCliente = this.dataJson.nombre_cliente
    this.idTransporte = this.dataJson.id_transporte
    this.motivoNoEntrega = this.dataJson.motivo_no_entrega_final
    if (this.dataJson.nro_documento === undefined) {
      console.log('nro documento null')
    } else {
      this.nroDocumento = this.dataJson.nro_documento
    }
    this.analista = store.state.user
    const date = new Date()
    const fechaActual = `${
      date.getFullYear().toString().padStart(4, '0')}-${
        (date.getMonth() + 1).toString().padStart(2, '0')}-${
          date.getDate().toString().padStart(2, '0')} ${
            date.getHours().toString().padStart(2, '0')}:${
              date.getMinutes().toString().padStart(2, '0')}:${
                date.getSeconds().toString().padStart(2, '0')}`
    this.fechaHoraComentario = fechaActual
  },
  methods: {
    ...mapActions('Comentarios', [
      'getcomentariosByDocumento', 'getcomentariosByTransporte', 'getcomentariosAll', 'insertComentarioGeneral'
    ]),
    ...mapActions('EntregaDiaria', [
      'getEntregaDiaria', 'actualizaEntregaDiariaMotivoNoEntrega'
    ]),
    makeToast (mensaje, titulo, variant) {
      this.$bvToast.toast(mensaje, {
        title: titulo,
        variant: variant,
        solid: true
      })
    },
    insertarComentario () {
      console.log('inserta comentario')
      let nroDocumento = ''
      if (this.dataJson.nro_documento === undefined) {
        console.log('nro documento null')
        nroDocumento = 0
      } else {
        nroDocumento = this.nroDocumento
      }
      let coordinacion = this.selectedCoordinacion
      if (this.selectedClasificacionComercial !== null && this.selectedCoordinacion === 'Comercial') {
        coordinacion = this.selectedCoordinacion + ' - ' + this.selectedClasificacionComercial
      }
      const comentarioInsertar = [
        {
          nro_documento: nroDocumento,
          fecha_ruta: this.fechaRuta,
          fecha_comentario: this.fechaHoraComentario,
          nro_cliente: this.nroCliente,
          nro_transporte: this.nroTransporte,
          nombre_cliente: this.nombreCliente,
          analista: this.analista,
          tipo_coordinacion: coordinacion,
          origen_contacto: this.selectedOrigenContacto,
          tipo_gestion: this.selectedGestion,
          respuesta: this.selectedRespuesta,
          motivo_no_entrega: '',
          estado: this.estado,
          id_entrega_diaria: this.idTransporte,
          comentarios: [
            {
              comentario: this.comentario,
              nro_documento: this.nroDocumento,
              fecha_ruta: this.fechaRuta.replace('T', ' ').substring(0, 18),
              fecha_comentario: this.fechaHoraComentario.replace('T', ' ').substring(0, 18),
              nro_cliente: this.nroCliente,
              nro_transporte: this.nroTransporte,
              nombre_cliente: this.nombreCliente,
              analista: this.analista,
              tipo_coordinacion: this.selectedCoordinacion,
              origen_contacto: this.selectedOrigenContacto,
              tipo_gestion: this.selectedGestion,
              respuesta: this.selectedRespuesta,
              motivo_no_entrega: '',
              estado: this.estadoComentario
            }
          ]
        }
      ]
      const objetoComentario = {
        comentario: comentarioInsertar,
        idTransporte: comentarioInsertar[0].nro_transporte
      }
      this.insertComentarioGeneral(objetoComentario).then((res) => {
        console.log(res)
        if (res) {
          this.makeToast('Comentario Transporte: ' + comentarioInsertar[0].nro_transporte, 'Comentario Insertado Exitosamente!', 'success')
        } else {
          this.makeToast('Comentario Transporte: ' + comentarioInsertar[0].nro_transporte, 'Error!', 'warning')
        }
        this.actualizaMotivoNoEntregaInicial()
        // this.$emit('updateComentarios', this.comentarios)
      })
    },
    actualizaMotivoNoEntregaInicial () {
      const entrega = this.dataJson
      console.log(this.motivoNoEntrega)
      entrega.motivo_no_entrega_final = this.motivoNoEntrega
      console.log(entrega)
      this.actualizaEntregaDiariaMotivoNoEntrega(entrega).then((res) => {
        this.$emit('updateComentarios', this.comentarios)
      })
    }
  },
  computed: {
    ...mapState('Comentarios', ['comentarios']),
    ...mapState(['motivosNoEntrega']),
    estadoComentario () {
      if (this.comentario.length > 0) {
        return true
      } else {
        return false
      }
      // return this.comentario.length > 2 ? true : false
    }
  }
}

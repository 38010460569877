import { render, staticRenderFns } from "@/components/Layout/Comentarios_totales/Comentarios_totales.html?vue&type=template&id=980aefb6&scoped=true&"
import script from "@/components/Layout/Comentarios_totales/Comentarios_totales.js?vue&type=script&lang=js&"
export * from "@/components/Layout/Comentarios_totales/Comentarios_totales.js?vue&type=script&lang=js&"
import style0 from "@/components/Layout/Comentarios_totales/Comentarios_totales.css?vue&type=style&index=0&id=980aefb6&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "980aefb6",
  null
  
)

export default component.exports